
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import Topic from './Topic'
import Participant from './Participant'
import { easeLinear } from "d3";

export default class DiscussionComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props);
        // SOMETHING LIKE THIS: this.state.reloadMatch();
        this.state = {
            discussion: undefined
        };

        this.reloadDiscussion = this.reloadDiscussion.bind(this);
        this.participantChanged = this.participantChanged.bind(this);
        this.topicChanged = this.topicChanged.bind(this);
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        console.error('COMPONENT DID LOAD: ', this.state);

        if (this.state.isReady && (!this.state.discussion || (this.state.discussion.ShortName != this.props.match.params.discussionCode))) {
            this.reloadDiscussion();
        }

    }
    componentDidWillUnmount(){
        console.error("Unmounted: ")
    }

    async reloadDiscussion() {
      if(this.state.discussion != undefined) this.setState({discussion: undefined});
      
        console.error('RELOADING Discussion NOW', this.props.match.params.discussionCode);
        let payload = this.context.createPayload()
        payload.AirtableWhere = "ShortName='" + this.props.match.params.discussionCode + "'";
        var reply = await this.context.moderator.GetDiscussions(payload);
        if (this.hasNoErrors(reply) && reply.Discussions && reply.Discussions.length) {
            var discussion = reply.Discussions[0];
            payload.AirtableWhere = `Discussion='${discussion.Name}'`
            reply = await this.context.moderator.GetDiscussionTopics(payload);
            if (this.hasNoErrors(reply)) {
                discussion.Topics = reply.DiscussionTopics;
            }
            reply = await this.context.moderator.GetDiscussionParticipants(payload);
            if (this.hasNoErrors(reply)) {
                discussion.Participants = reply.DiscussionParticipants;
            }
            reply = await this.context.moderator.GetTopicAgreements(payload);
            if (this.hasNoErrors(reply)) {
                discussion.Agreements = reply.TopicAgreements;
            }
            console.error("ABOUT TO CHECK FALLACIES:: ", reply)

            reply = await this.context.moderator.GetTopicFallacies(payload);
            if (this.hasNoErrors(reply)) {
                console.error("Loading Fallacies in Discussion", reply.TopicFallacies)
                discussion.Fallacies = reply.TopicFallacies;
            } else {
                console.error("ERROR:: ", reply)

            }

            console.error('ABOUT TO RENDER DISCUSSION: ', discussion);
            var newState = { discussion: discussion, reloadRequested: true }
            this.setState(newState);
        } else {
            console.error('GOT ERROR RELOADING DISCUSSION NOW!', reply);
        }
    }

    async participantChanged(participantId: any) {
        console.error('GOT RELOAD DISCUSSION REQUEST FROM EVENT', participantId);
        this.state.discussion.CurrentParticipant = participantId;
        var payload = this.context.createPayload();
        payload.Discussion = JSON.parse(JSON.stringify(this.state.discussion));
        delete payload.Discussion.Topics;
        delete payload.Discussion.Participants;
        delete payload.Discussion.Agreements;
        delete payload.Discussion.Fallacies;

        payload.Discussion.Status = payload.Discussion.Status == "On" ? "Off" : "On";

        var reply = await this.context.moderator.UpdateDiscussion(payload);
        if (this.hasNoErrors(reply)) {
            reply.Discussion.Topics = this.state.discussion.Topics;
            reply.Discussion.Participants = this.state.discussion.Participants;
            reply.Discussion.Agreements = this.state.discussion.Agreements;
            reply.Discussion.Fallacies = this.state.discussion.Fallacies;
            this.setState({ discussion: reply.Discussion, reloadRequested: true });
        }
    }

    async topicChanged(changeRequest: any) {
        let relatedTopicSubject = changeRequest.relatedTopicSubject;        
        let discussionTopicId = changeRequest.discussionTopicId;
        console.error('Change Request: ', changeRequest);
        if (discussionTopicId) {
            await this.setCurrentTopic(discussionTopicId);
        } else if (relatedTopicSubject) {
            if (!changeRequest.parentTopic) alert('Error, parent topic missing from sub topic');
            else if (!changeRequest.discussionParticipant) alert('Error, discussion participant missing from sub topic'); 
            else await this.addRelatedTopic(relatedTopicSubject, changeRequest.discussionParticipant, changeRequest.parentTopic);
        }
    }

    async addRelatedTopic(relatedTopicSubject: any, discussionParticipant : any, parentTopic:any) {
        var payload = this.context.createPayload();
        payload.DiscussionTopic = {
            Discussion: this.state.discussion.DiscussionId,
            ParentTopic: parentTopic?.DiscussionTopicId,
            DiscussionParticipant: discussionParticipant?.DiscussionParticipantId,
            Subject: relatedTopicSubject
        }
        var reply = await this.context.moderator.AddDiscussionTopic(payload);
        if (this.hasNoErrors(reply)) {
            console.error('UPDATING DISCUSSION', reply);
            var newTopic = reply.DiscussionTopic;
            this.state.discussion.Topics.unshift(newTopic);
            this.state.discussion.DiscussionTopics.unshift(newTopic.DiscussionTopicId);
            this.setState((prevState: any) => ({
                discusion: {
                    ...prevState.discusion,
                    Topics: newTopic,
                    DiscusionTopics: newTopic.DiscussionTopicId
                }
            }));

            this.state.discussion.LastModified = new Date();
            this.setState({ discussion: this.state.discussion });
        }
    }


    private async setCurrentTopic(discussionTopicId: any) {
        this.state.discussion.CurrentTopic = discussionTopicId;
        var payload = this.context.createPayload();
        payload.Discussion = JSON.parse(JSON.stringify(this.state.discussion));
        delete payload.Discussion.Topics;
        delete payload.Discussion.Participants;
        delete payload.Discussion.Agreements;
        delete payload.Discussion.Fallacies;

        var reply = this.context.moderator.DiscussionUpdated(payload);

        reply = await this.context.moderator.UpdateDiscussion(payload);
        if (this.hasNoErrors(reply)) {
            var discussion = reply.Discussion;
            discussion.Topics = this.state.discussion.Topics;
            discussion.Participants = this.state.discussion.Participants;
            discussion.Agreements = this.state.discussion.Agreements;
            discussion.Fallacies = this.state.discussion.Fallacies;

            payload.AirtableWhere = `RECORD_ID()='${discussionTopicId}'`;
            reply = await this.context.moderator.GetDiscussionTopics(payload);
            if (this.hasNoErrors(reply)) {
                var newTopic = reply.DiscussionTopics[0];
                var existingTopic = discussion.Topics.filter((topic: any) => topic.DiscussionTopicId == discussionTopicId)[0];
                var index = discussion.Topics.indexOf(existingTopic);
                console.error('GOT UPDATED VERSION OF TOPIC: ', reply, existingTopic, index);
                discussion.Topics[index] = newTopic;
                this.setState({ discussion: discussion, reloadRequested: true });
            }
        }
    }

    render() {
        const { discussion } = this.state;
        return (
            <IonPage>
                {!discussion ? <IonLoading isOpen={true} message={'Loading...'} /> : <>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonMenuButton />
                            </IonButtons>
                            <div style={{ float: 'right' }}>{discussion?.Name}</div>
                            <IonTitle>{discussion?.Subject || 'Loading discussion...'}</IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent fullscreen>

                        <div style={{ overflow: "scroll", height: "100%" }}>
                            <div style={{ float: 'right' }}>
                                <button onClick={this.reloadDiscussion}>Reload</button>
                            </div>

                            <IonButton routerLink={"/sub/" + discussion?.SubGroupShortName} onClick={()=> this.setState({discussion: undefined})}>{discussion?.SubGroupName}</IonButton>

                            <div style={{ clear: 'both', borderTop: 'solid black 1px' }}>
                                {/* <h2 style={{clear: 'both', textAlign: 'center'}}>{discussion?.CurrentTopicSubject || 'loading...'}</h2>
                            <hr /> */}
                                <div>
                                    {discussion?.Topics?.filter((topic: any) => !topic.ParentTopic).map((topic: any) => {
                                        return <div key={topic.DiscussionTopicId + discussion.LastModifiedTime}>
                                            <Topic discussion={discussion} topic={topic} key={topic.DiscussionTopicId} 
                                                    topicChanged={(discussionTopicId: any) => this.topicChanged(discussionTopicId)}
                                                participantChanged={this.participantChanged} />
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </IonContent>
                </>}
            </IonPage>

        );
    }
}