import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import { EffortlessBaseComponent } from './services/EffortlessBaseComponent'

import Menu from './components/Menu';
import Home from './components/Home';
import Organizations from './pages/organizations/Organizations';
import EditOrganization from './pages/organizations/EditOrganization';
import Organization from './pages/organizations/Organization';
import UserOrganization from './pages/organizations/UserOrganization';
import People from './pages/hosts/Hosts';
import Person from './pages/hosts/Host';
import AddOrganization from './pages/organizations/AddOrganization';
import OrganizationGroups from './pages/organizationgroups/OrganizationGroups';
import OrganizationGroup from './pages/organizationgroups/OrganizationGroup';
import SubGroups from './pages/subgroups/SubGroups';
import SubGroup from './pages/subgroups/SubGroup';
import UserSubGroup from './pages/subgroups/UserSubGroup';
import AddDiscussion from './pages/subgroups/AddDiscussion';
import Discussions from './pages/discussions/Discussions';
import Discussion from './pages/discussions/Discussion';
import Profile from './pages/profile/Profile';
import Login from './pages/auth/Login'
import Logout from './pages/auth/Logout'
import Register from './pages/auth/Register'

import D3demo from './pages/d3/d3demo'
// import GlobalState from './GlobaleState';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'; 
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';


class App extends EffortlessBaseComponent {
  constructor(props: any) {
    super(props);
    this.state = {
      location: ""

    }
    this.isAuthenticated = false;
  }

render(){
  // var location = useLocation();
  // console.error("Route:", location.pathname)

    return (
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/" exact={true}>
                <Redirect to="/about" />
              </Route>
              <Route path="/about" exact={true} component={Home} />
              <Route path="/login" exact={true} component={Login} />
              <Route path="/logout" exact={true} component={Logout} />
              <Route path="/register" exact={true} component={Register} />
              <Route path="/organizations/add" exact={true} component={AddOrganization}  />
              <Route path="/organizations" exact={true} component={Organizations}  />
              <Route path="/org/:orgCode" exact={true} component={Organization} />
              <Route path="/userorg/:orgCode" exact={true} component={UserOrganization} />
              <Route path="/org/:orgCode/edit" exact={true} component={EditOrganization} />
              <Route path="/people" exact={true} component={People} />
              <Route path="/people/:personCode" exact={true} component={Person} />
              <Route path="/groups" exact={true} component={OrganizationGroups} />
              <Route path="/group/:groupCode"   exact={true} component={OrganizationGroup} />
              <Route path="/subs" exact={true}  component={SubGroups} />
              <Route path="/sub/:subGroupCode"  exact={true}  render={(props) => <SubGroup {...props} subGroupCode={props.match.params.subGroupCode} />} />
              <Route path="/userSub/:subGroupCode"  exact={true}  render={(props) => <UserSubGroup {...props} subGroupCode={props.match.params.subGroupCode} />} />
              <Route path="/sub/:subGroupCode/add" exact={true} component={AddDiscussion} />
              <Route path="/discussions" exact={true} component={Discussions} />
              <Route path="/discussion/:discussionCode" exact={true} component={Discussion} />
              <Route path="/moderators" exact={true} component={People} />
              <Route path="/account" exact={true} component={Profile} />
            
              {/*Note:  Route bellow is for testing ... */}
              <Route path="/d3" exact={true}>
                <D3demo />
              </Route>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    );
  }
};

export default App;
