
import React from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useHistory } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'

export default class DiscussionssComponent extends EffortlessBaseComponent {

  constructor(props: any) {
    super(props)

    this.state = {
      people: []
    };
  }

  async onReady() {
    this.loadData();
  }

  async loadData() {
    var payload = this.context.createPayload();
    payload.AirtableWhere = "Roles='Host'"
    var reply = await this.context.moderator.GetPeople(payload);
    if (this.context.hasNoErrors(reply)) {
      this.setState({ people: reply.People });
    }
  }


  render() {
    const { people } = this.state;

    return (
      <IonPage>
        {!people ? <IonLoading isOpen={true} message={'Loading...'} /> :
          <>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonMenuButton />
                </IonButtons>
                <IonTitle>People</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
              <div>
                PEOPLE: {people?.length}
              </div>
              {people?.map((person: any) =>
                <div key={person.PersonId}>{person.Name}</div>
              )};
            </IonContent>
          </>}
      </IonPage>
    );
  }
}