import { IonContent, IonItem, IonRow } from "@ionic/react"
import { EffortlessBaseComponent } from "../../services/EffortlessBaseComponent"

export default class OrganizationTypeWidgetComponent extends EffortlessBaseComponent {
    constructor(props: any) {
        super(props)
    }

    componentDidUpdate() {
        var orgTypeName = this.props.organizationTypeName;
        if (this.state.organizationTypeName != orgTypeName) {
            if (this.context.organizationTypesByName) {
                this.setState({
                    organizationType: this.context.organizationTypesByName[orgTypeName],
                    organizationTypeName: orgTypeName
                });
            }   
        }
    }

    render() {
        return <IonContent style={{ height: '15em' }}>
            <div>
                <h3>{this.state.organizationType?.PluralDisplayName}</h3>
            </div>
            <div>
                {this.state.organizationType?.SubGroupOwnerDisplayName} / {this.state.organizationType?.PluralSubGroupOwnerDisplayName}
            </div>
            <div>
                {this.state.organizationType?.DiscussionParticipantDisplayName} / {this.state.organizationType?.PluralDiscussionParticipantDisplayName}
            </div>
            <div>
                {this.state.organizationType?.GroupDisplayName} / {this.state.organizationType?.PluralGroupDisplayName} ({this.state.organizationType?.GroupCodeDisplayName})
            </div>
            <div style={{paddingLeft:'1em'}}>
                {this.state.organizationType?.SubGroupDisplayName} / {this.state.organizationType?.PluralSubGroupDisplayName} ({this.state.organizationType?.SubGroupCodeDisplayName})
            </div>
            <div style={{paddingLeft:'2em'}}>
                {this.state.organizationType?.DiscussionDisplayName} / {this.state.organizationType?.PluralDiscussionDisplayName}
            </div>
            <div style={{paddingLeft:'3em'}}>
                {this.state.organizationType?.TopicDisplayName} / {this.state.organizationType?.PluralTopicDisplayName}
            </div>

        </IonContent>
    }
}