import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';
import { EffortlessBaseComponent } from '../../../services/EffortlessBaseComponent';
import AddOrgBase from './AddOrgBase';

export default class AddCompanyComponent extends AddOrgBase {
    
    
    render() {
        return <div>
            <h3>Add Company</h3>
            <IonItem>
                <IonLabel>Company Name: </IonLabel>
                <IonInput value={this.state.newOrg?.Name} 
                            onIonChange={(event) => this.updateNewOrg(event, 'Name')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Client: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstOrgGroup} placeholder="Bobby Jones"
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstOrgGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Project: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstSubGroup} placeholder="Project ABC"
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstSubGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Meeting: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstDiscussion} placeholder="Discovery call"
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstDiscussion')} >
                </IonInput>
            </IonItem>

            <IonItem lines="none">
                <IonButton onClick={(e: any) => this.addOrganization(e)}>Create Company</IonButton>
            </IonItem>
        </div>
    }
}