import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonInput, IonButton, IonItemGroup, IonCol, IonRow, IonGrid } from "@ionic/react";
import React from "react";
import { EffortlessBaseComponent } from "../../services/EffortlessBaseComponent";

export default class RegisterComponent extends EffortlessBaseComponent {
    constructor(props: any) {
        super(props)
        this.state = {
            newUser: {
                EmailAddress: "",
                DisplayName: "",
                Initials: "",
                Location: "",
            }
        }
        this.isAuthenticated = false;
    }

    updateEmailAddress(event: any) {
        this.state.newUser.EmailAddress = event.target.value;
        this.checkReady();
    }
    checkReady() {
        var userComplete = this.state.newUser?.EmailAddress?.length &&
                            this.state.newUser?.DisplayName?.length &&
                            this.state.newUser?.Initials?.length &&
                            this.state.newUser?.Location?.length;
        this.setState({ newUser: this.state.newUser, userComplete: userComplete });
    }

    updateDisplayName(event: any) {
        this.state.newUser.DisplayName = event.target.value;
        this.checkReady();
    }

    updateInitials(event: any) {
        this.state.newUser.Initials = event.target.value;
        this.checkReady();
    }

    updateLocation(event: any) {
        this.state.newUser.Location = event.target.value;
        this.checkReady();
    }

    async register(e: any) {
        e.preventDefault();
        console.error('REGISTERING NOW: ', this.state);
        var payload = this.context.createPayload();
        payload.Person = this.state.newUser;
        var reply = await this.context.guest.AddPerson(payload);
        if (this.hasNoErrors(reply)) {
            var person = reply.Person;
            payload.EmailAddress = person.EmailAddress;
            reply = await this.context.guest.ValidateTemporaryAccessToken(payload);
            if (this.hasNoErrors(reply)) {
              this.context.saveAccessToken(reply.AccessToken);
              await this.context.setupDefaultUserOrganization();
              this.props.history.push('/organizations');
            }
        } else {
            alert(reply.ErrorMessage);
        }
    }
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Register</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="7" offset="2">
                                <IonItemGroup >
                                    <IonItem>
                                        <IonLabel>Email Adderess: </IonLabel>
                                        <IonInput value={this.state.newUser.EmailAddress} onIonChange={(event) => this.updateEmailAddress(event)} >
                                        </IonInput>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel>DisplayName: </IonLabel>
                                        <IonInput value={this.state.newUser.DisplayName} onIonChange={(event) => this.updateDisplayName(event)} >
                                        </IonInput>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Initials: </IonLabel>
                                        <IonInput value={this.state.newUser.Initials} onIonChange={(event) => this.updateInitials(event)} >
                                        </IonInput>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Location: </IonLabel>
                                        <IonInput value={this.state.newUser.Location} onIonChange={(event) => this.updateLocation(event)} >
                                        </IonInput>
                                    </IonItem>

                                    <IonItem lines="none">
                                        <IonRow>
                                            <IonCol >
                                                <IonButton expand="block" onClick={(e: any) => this.register(e)}  color="success"
                                                            style={{ float: 'right' }} disabled={!this.state.userComplete}>
                                                    Sign Up
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
Already a customer?
                                            <IonCol>
                                                <IonButton expand="block" routerLink="/login">
                                                    Login
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonItem>
                                </IonItemGroup>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}