
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { addOutline, pencilOutline } from "ionicons/icons";

export default class EditOrganizationComponent extends EffortlessBaseComponent {
    didMount: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            organization: undefined,
            reloadCalled: false
        };
    }

    async reloadOrganization() {
        if (!this.state.reloadCalled) this.setState({ reloadCalled: true });
        let payload = this.context.createPayload()
        payload.AirtableWhere = "ShortName='" + this.props.match.params.orgCode + "'";
        var reply = await this.context.moderator.GetOrganizations(payload);
        if (this.hasNoErrors(reply) && reply.Organizations && reply.Organizations.length) {
            var organization = reply.Organizations[0];
            console.error('GOT ORG: ', organization);
            payload.AirtableWhere = `OrganizationName='${organization.Name.replace(/\'/g, "\\'")}'`;
            reply = await this.context.moderator.GetOrganizationGroups(payload);
            if (this.hasNoErrors(reply)) {
                organization.Groups = reply.OrganizationGroups;
            }
            var newState = { organization: organization, reloadCalled: true };
            this.setState(newState);
        } else {
            console.error('ERRO LOADING ORG: ', reply.ErrorMessage);
        }
    }


    componentDidUpdate() {
        super.componentDidUpdate();
        if (this.state.isReady &&
            (!this.state.reloadCalled || (this.state.organization && (this.state.organization.ShortName != this.props.match.params.orgCode)))) {
            this.reloadOrganization();
        }
    }

    notesChange(event: any) {
        this.state.organization.Notes = event.target.value;
        this.setState({ organization: this.state.organization });
    }


    async saveOrganization() {
        console.error('UPDATING ORG NOW...', this.state.organization);
        var payload = this.context.createPayload();
        payload.Organization = this.state.organization;
        var reply = await this.context.moderator.UpdateOrganization(payload);
        if (this.hasNoErrors(reply)) {
            console.error('ORG UPDATED: ', reply);
            this.props.history.push('/org/' + this.state.organization.ShortName);
        }
    }

    render() {
        console.error('rendering');
        const { organization } = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <div style={{ float: 'right' }}>
                            <button onClick={() => this.reloadOrganization()}>Reload</button>
                        </div>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div>
                        <div>
                            {organization?.Attachments?.length ? <div><img src={organization?.Attachments[0].url} style={{ width: '6em', float: 'left' }} /></div> : undefined}
                            <h3>{organization?.Groups?.length} {organization?.PluralGroupDisplayName} </h3>
                            <div>
                                <b>Description: </b>
                            </div>
                            <div>
                                <textarea value={organization?.Notes}
                                    style={{ margin: '1em', width: '80%', height: '3em' }}
                                    onChange={(event: any) => this.notesChange(event)}>

                                </textarea>
                            </div>
                            <IonButton onClick={() => this.saveOrganization()}>Save</IonButton>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );
    }

}