import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';
import AddOrgBase from './AddOrgBase'

export default class AddCallInShowComponent extends AddOrgBase {
    
    
    render() {
        return <div>
            <h3>Add Call In Show</h3>
            <IonItem>
                <IonLabel>Show Name: </IonLabel>
                <IonInput value={this.state.newOrg?.Name} 
                            onIonChange={(event) => this.updateNewOrg(event, 'Name')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Seasons?: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstOrgGroup} placeholder="Seasons 1"
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstOrgGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Episodes: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstSubGroup} placeholder="Episode 1"
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstSubGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Call: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstDiscussion} placeholder="1st Call"
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstDiscussion')} >
                </IonInput>
            </IonItem>

            <IonItem lines="none">
                <IonButton onClick={(e: any) => this.addOrganization(e)}>Create Call in Show</IonButton>
            </IonItem>
        </div>
    }
}