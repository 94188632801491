import * as React from 'react';
import { Observable, BehaviorSubject, Subscription, timer, combineLatest } from 'rxjs';
import { filter, share, map, catchError, take, tap } from 'rxjs/operators';
import generateGuestActor from '../sassymq/jsActors/smqGuest.js';
import generateModeratorActor from '../sassymq/jsActors/smqModerator.js';
import generateOrgUserActor from '../sassymq/jsActors/smqOrgUser.js';
declare global {
  interface Window {
    GDS: any;
  }
}

export class GDS {
  organizationTypes: any;
  organizationTypesByName: any;
  orgUser: any;

  onDiscussionUpdated(payload: any, frame: any) {
    console.error('RECEIVED MESSAGE ABOUT DISCUSSION: ', payload, frame)
  }

  guestIsConnected() {
    return this.guest?.client?.connected;
  }
  moderatorIsConnected() {
    return this.moderator?.client?.connected;
  }
  guest: any;

  public readiness$: BehaviorSubject<any> = new BehaviorSubject(false);
  public notReadiness$: BehaviorSubject<any> = new BehaviorSubject(false);
  public d3event$: BehaviorSubject<any> = new BehaviorSubject(false);
  accessToken: string = "";
  smqGuest: any = "";
  whoAmI: any = null;
  isCustomer: boolean = false;
  isAdmin: boolean = false;
  isEmployee: boolean = false;
  isManager: boolean = false;
  role: string = "Guest";
  vhost: string = "";
  smqUsername: string = "";
  smqPassword: string = "";
  smqUser: any = null;
  rabbitEndpoint: string = "";
  isGuestConnected: boolean = false;
  firstLoad: boolean = false;
  organizations: any;
  moderator: any;
  isReady: boolean = false;
  fallacies: any;
  fallaciesById: any;
  hideMenu: boolean = false;
  isModeratorReady: boolean = false;


  constructor() {
    var self = this;
    window.GDS = this;
    this.guest = generateGuestActor();
    this.guest.rabbitEndpoint = 'wss://effortlessapi-rmq.ssot.me:15673/ws'
    this.guest.connect('ej-emma-mediates', 'smqPublic', 'smqPublic', (msg: any) => {
      console.error('MESSAGE', msg);
    }, (connected: any) => {
      console.error('connected', this.guest);
      this.loadData();
    });
  }
  async loadData() {
    await this.checkAccessToken();
    await this.loadGuestData();
  }

  async checkAccessToken() {
    var accessToken = localStorage.getItem('accessToken');
    if ((accessToken == "undefined") || (accessToken == "null")) accessToken = null;
    console.error(accessToken)

    this.saveAccessToken(accessToken);
  }

  async loginModerator(emailAddress: any, password: any) {
    var payload = {
      EmailAddress: emailAddress,
      DemoPassword: password
    }
    var reply = await this.guest.ValidateTemporaryAccessToken(payload)
    console.error('GOT VALIDATION REPLY...', payload, reply);
    if (this.hasNoErrors(reply)) {
      this.saveAccessToken(reply.AccessToken);
    }
  }

  groupBy = (key: any) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  hasNoErrors(reply: any) {
    if (!reply) {
      console.error('ERROR: ', 'Missing payload response', { status: 'danger' });
      return false;
    }
    else if (reply.ErrorMessage) {
      console.error('ERROR: ', reply.ErrorMessage, { status: 'danger' });
      return false;
    }

    return true;
  }

  async loadGuestData() {
    let payload = this.createPayload()
    var jsonStr: string = localStorage.getItem("organizationTypes") || "null";
    if (jsonStr == 'undefined') jsonStr = "null";
    let orgTypeData = JSON.parse(jsonStr);
    if (!orgTypeData) {
      orgTypeData = await this.relaodOrgTypeData(payload, orgTypeData);
    }
    this.organizationTypes = orgTypeData
    this.organizationTypesByName = Object.assign({}, ...orgTypeData.map((orgType: any) => ({ [orgType.Name]: orgType })));
  }

  async loadStaticData(moderator: any) {
    console.error('ORGANIZTION TYPES LOADED: ', this);
    if (this.accessToken && moderator) {
      let payload = this.createPayload()
      console.error('UPDATING STATIC DATA NOW', this.accessToken, moderator, moderator?.client?.connected, payload);
      let organizationReply = await moderator.GetOrganizations(payload);
      console.error('CONNECTED GUEST', organizationReply);
      this.organizations = organizationReply.Organizations;

      let jsonStr = localStorage.getItem("fallacies") || "null";
      if (jsonStr == 'undefined') jsonStr = "null";
      let fallaciesData = JSON.parse(jsonStr);
      if (!fallaciesData) {
        fallaciesData = await this.relaodFallacyData(payload, fallaciesData);
      }
      this.fallacies = fallaciesData
      console.error("Fullstack Fallacy", fallaciesData)
    }
  }

  private async relaodFallacyData(payload: any, fallaciesData: string | null) {
    let fallacyReply = await this.moderator.GetFallacies(payload);
    fallaciesData = fallacyReply.Fallacies;

    console.error("Fallacies GDS:: ", fallaciesData);
    localStorage.setItem("fallacies", JSON.stringify(fallaciesData));
    return fallaciesData;
  }

  private async relaodOrgTypeData(payload: any, orgTypeData: string | null) {
    let orgTypesReply = await this.guest.GetOrganizationTypes(payload);
    // console.error("OrganizationTypes GDS:: ", orgTypesReply);

    orgTypeData = orgTypesReply.OrganizationTypes;
    
    // console.error("OrganizationTypes GDS:: ", orgTypeData);
    localStorage.setItem("organziationTypes", JSON.stringify(orgTypeData));
    return orgTypeData;
  }

  async setupDefaultUserOrganization() {
    let organizationTypeId : string = this.organizationTypesByName["RandomThoughts"].OrganizationTypeId;
    let organizationName : string = `Personal Notes`;
    let organizationCode : string = window.GDS.whoAmI?.Name;
    let organizationGroupName : string = 'Notes';
    await this.addNewOrganization(organizationTypeId, organizationCode, organizationName, organizationGroupName);
  }

  async addNewOrganization(organizationTypeId: string, organizationCode: string, organizationName: string, organizationGroupName: string) {
    console.error('Adding default organization');
    var payload = this.createPayload();
    payload.Organization = {
      OrganizationCode: `${organizationCode}`,
      Name: organizationName,
      OrganizationType: organizationTypeId
    };
    var reply = await this.moderator.AddOrganization(payload);
    if (this.hasNoErrors(reply)) {
      var org = reply.Organization;
      payload.OrganizationUser = {
        Organization: org.OrganizationId,
        User: this.whoAmI.PersonId,
      };

      reply = await this.moderator.AddOrganizationUser(payload);
      if (this.hasNoErrors(reply)) {
        payload.OrganizationGroup = {
          Name: organizationGroupName,
          Organization: org.OrganizationId,
          GroupNumber: 1
        };
        reply = await this.moderator.AddOrganizationGroup(payload);
        if (this.hasNoErrors(reply)) {
          console.error('CREATED DEFAULT ORGANIZTION');
        }
      }
    }
  }

  dontConnect() {
    console.error('NOT Connecting now');
    setTimeout(() => {
      this.readiness$.next(true);
    }, 1000);
  }

  getDate(date: any) {
    if (date && date.getDate) return date;
    else if (typeof date === "string") {
      var dtString = date.substring(0, 19) + 'Z';
      return new Date(dtString);
    }
    else if (date.toISOString) return date;
    else return new Date(date);
  }

  // if !accessToke then not logged in
  async saveAccessToken(accessToken: any) {
    if ((accessToken == 'undefined') || (accessToken == 'null')) {
      accessToken = null;
    }
    var gds = this;
    delete gds.whoAmI;
    console.error('SAVING ACCESS TOKEN NOW: ', accessToken);
    gds.accessToken = accessToken;
    localStorage.setItem('accessToken', accessToken);
    gds.disconnectExistingModerator();
    await this.completeConnect(gds)
  }

  disconnectExistingModerator() {
    this.moderator?.disconnect();
    this.orgUser?.disconnect();
  }

  private async completeConnect(gds: this) {
    console.error('COMPLETEING CONNECT', gds);
    if (gds.accessToken) {
      var waiReply = await gds.guest.WhoAmI(gds.createPayload());
      gds.whoAmI = waiReply.SingletonAppUser;
      gds.connect();
    } else if (!this.isReady) {
      gds.whoAmI = {
        RoleS: "Guest"
      };
      this.isReady = true;
      this.isModeratorReady = false;
      this.readiness$.next(true);
    }
  }

  connect() {
    if (this.moderator) {
      console.error('DISCONNECTING MODERATOR NOW...');
      this.moderator.disconnect();
    }
    this.moderator = generateModeratorActor();
    if (this.orgUser) {
      this.orgUser.disconnect();
    }
    this.orgUser = generateOrgUserActor();
    this.orgUser.rabbitEndpoint = this.moderator.rabbitEndpoint = 'wss://effortlessapi-rmq.ssot.me:15673/ws'
    this.moderator.connect('ej-emma-mediates', 'smqPublic', 'smqPublic', (msg: any) => { }, () => {
      if (this.accessToken) {
        this.moderator.onModeratorDiscussionUpdated = this.onDiscussionUpdated;
        console.error('CONNECTED MODERATOR: ', this.accessToken);
        this.orgUser.connect('ej-emma-mediates', 'smqPublic', 'smqPublic', (msg: any) => { }, () => {
          this.isReady = true;
          this.isModeratorReady = true;
          this.loadStaticData(this.moderator);
          this.readiness$.next(true);
        });
      }
    });
  }
  createPayload(): any {
    return { AccessToken: this.accessToken };
  }

  public logout() {
    this.isCustomer = false;
    this.isAdmin = false;
    this.isEmployee = false;
    this.whoAmI = null;
  }
}
