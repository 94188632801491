import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonInput, IonItem, IonLabel, IonGrid, IonItemGroup, IonRow, IonCol, IonButtons, IonMenuButton } from "@ionic/react";
import { error } from "console";
import { truncateSync } from "fs";
import React from "react";
import { EffortlessBaseComponent } from "../../services/EffortlessBaseComponent";

export default class LoginComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props)
        this.state = {
            creds: {
                emailAddress: "",
                password: ""
            },
            notReadyFired: false
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        if (!this.state.notReadyFired) {
            if (this.context.accessToken) this.context.saveAccessToken(null);
            this.setState({ notReadyFired: true, isReady: this.state.isReady });
        }

        this.isAuthenticated = false;
    }

    async loginModerator(e: any) {
        if (this.context.guestIsConnected()) {
            await this.context.loginModerator(this.state.creds.emailAddress, this.state.creds.password);
            this.context.readiness$.next(true);
            e.preventDefault();
            this.props.history.push('/organizations')
        }
    }

    updateEmailAddress(event: any) {
        this.state.creds.emailAddress = event.target.value;
        this.setState({ creds: this.state.creds });
    }

    updatePassword(event: any) {
        this.state.creds.password = event.target.value;
        this.setState({ creds: this.state.creds });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>Login</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="7" offset="2">
                                <IonItemGroup>
                                    <IonItem>
                                        <IonLabel>Email: </IonLabel>
                                        <IonInput value={this.state.creds.emailAddress} onIonChange={(event) => this.updateEmailAddress(event)} >
                                        </IonInput>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Password: </IonLabel>
                                        <IonInput value={this.state.creds.password} onIonChange={(event) => this.updatePassword(event)} >
                                        </IonInput>
                                    </IonItem>
                                    <IonItem lines="none">
                                        <IonRow>
                                            <IonCol >
                                                <IonButton disabled={!this.state.isReady} expand="block" onClick={(e) => this.loginModerator(e)}>
                                                    Login
                                                </IonButton>
                                                <IonButton expand="block" routerLink="/register">
                                                    Register
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonItem>
                                </IonItemGroup>

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}