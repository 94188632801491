
import React from "react";
import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonPopover,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { curveNatural } from "d3";
import { BehaviorSubject } from "rxjs";
import { addOutline, thumbsDownOutline, thumbsUpOutline, thumbsUpSharp } from "ionicons/icons";

export default class TopicParticipantComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            discussion: props.discussion,
            discussionParticipant: props.discussionParticipant,
            topicChanged: props.topicChanged,
            participantChanged: props.participantChanged,
            topic: props.topic,
            popoverEvent: undefined,
            showPopover: false,

        };

        this.reloadTopic = this.reloadTopic.bind(this);
    }


    async onReady() {
        this.reloadTopic();
    }

    async reloadTopic() {
        // do nothing on reload
    }

    shouldComponentUpdate() {
        return true;
    }

    async addAgreement() {
        await this.setAgreement('Agree');
    }

    async addDisagreement() {
        await this.setAgreement('Disagree');
    }

    private async setAgreement(status: string) {
        console.error('Updating Agreement: ', status, this.state.discussion.Agreements);
        var existingAgreements = this.lookForExistingAgreement();

        var existingAgreement = (existingAgreements && existingAgreements.length) ? existingAgreements[0] : null;
        var reply = null;

        if (existingAgreement) {
            reply = await this.updateExistingTopicAgreement(existingAgreement, status, reply);
        } else {
            reply = await this.addTopicAgreement(status, reply);
        }

        if (this.hasNoErrors(reply)) {
            this.state.discussion.Agreements.push(reply.TopicAgreement);
        }
        this.state.discussion.LastModifiedTime = new Date();
        this.state.topicChanged({ discussionTopicId: this.state.discussion.CurrentTopic });
    }

    private async addTopicAgreement(status: string, reply: any) {
        var payload = this.context.createPayload();
        console.error('Adding new agreement');
        payload.TopicAgreement = payload.TopicAgreement || {
            Topic: this.state.topic.DiscussionTopicId,
            DiscussionParticipant: this.state.discussionParticipant.DiscussionParticipantId
        };
        payload.TopicAgreement.Status = status;
        reply = await this.context.moderator.AddTopicAgreement(payload);
        return reply;
    }

    private async updateExistingTopicAgreement(existingAgreement: any, status: string, reply: any) {
        var payload = this.context.createPayload();
        console.error('Updating existing agreement');
        var index = this.state.discussion.Agreements.indexOf(existingAgreement);
        this.state.discussion.Agreements.splice(index, 1);
        payload.TopicAgreement = existingAgreement;
        existingAgreement.Status = status;
        reply = await this.context.moderator.UpdateTopicAgreement(payload);
        return reply;
    }

    private lookForExistingAgreement() {
        return this.state.discussion.Agreements.filter((agreement: any) => (
            (agreement.Topic == this.state.topic.DiscussionTopicId) &&
            (agreement.DiscussionParticipant == this.state.discussionParticipant.DiscussionParticipantId)));
    }

    getParticipantUrl(discussionparticipant: any) {
        return (discussionparticipant.ParticipantAvatar && discussionparticipant.ParticipantAvatar.length) ?
            discussionparticipant.ParticipantAvatar[0].url : '/assets/avatar.png';
    }

    async relatedTopicSubjectChanged(event: any, topic: any) {
        console.error('RELATED TOPIC SUBJECT CHANGED: ', topic?.Subject, this.state.discussion?.relatedTopicSubject);
        this.state.discussion.relatedTopicSubject = event.target.value;
        this.setState({ discussion: this.state.discussion });
    }

    keyPressed(event: any) {
        console.error('Keypress: ', this.state.discussion?.relatedTopicSubject);

        if (event?.code == 'Enter') {
            console.error('ENTER PRESSED - CREATING NEW TOPIC', this.state.discussionParticipant, this.state.topic, this.state.discussion.relatedTopicSubject);
            this.state.topicChanged({
                relatedTopicSubject: this.state.discussion.relatedTopicSubject,
                discussionParticipant: this.props.discussionParticipant,
                parentTopic: this.state.topic
            });
            this.state.discussion.relatedTopicSubject = "";

            this.setState({ topic: this.state.topic });
        }
    }

    render() {
        const { discussion, discussionParticipant, topic } = this.state;
        return (
            <IonList  className="participant-container" >

                <IonItem lines="none" id="participant" style={{ clear: 'both', padding: "0.1em"}}
                    // layout="icon-start"
                    className={(discussion.CurrentParticipant == discussionParticipant.DiscussionParticipantId) ? 'speaking' : 'notspeaking'}
                    onClick={() => this.state.participantChanged(discussionParticipant.DiscussionParticipantId)}
                >

                    <IonAvatar  style={{border: "", width: "2.5em", height: "2.5em"}}>
                        <img src={this.getParticipantUrl(discussionParticipant)} />
                    </IonAvatar>
                    <IonLabel>{discussionParticipant?.DisplayName}</IonLabel>
                    <IonButtons  id="participant-op">
                            <IonButton onClick={() => this.addAgreement()}   >
                                <IonIcon color="success" icon={thumbsUpOutline} />
                            </IonButton>
                            <IonButton onClick={() => this.addDisagreement()} >
                                <IonIcon color="danger" icon={thumbsDownOutline} />
                            </IonButton>
                        </IonButtons>
                </IonItem>

                <IonItemGroup style={{ border: "" }}
                    id="participant-op" >
                    <div>
                       
                        {
                            <div >
                                <IonInput type="text" name="newSubTopic"
                                placeholder="+ New Topic ..."
                                value={discussion.relatedTopicSubject}
                                    onKeyDown={(event: any) => this.keyPressed(event)}
                                    style={{ border: "1px solid gray", width: '100%' }} onIonChange={(e) => this.relatedTopicSubjectChanged(e, topic)} > </IonInput>
                                {/* <div style={{ clear: 'both' }}>&nbsp;</div> */}
                            </div>}
                    </div>
                </IonItemGroup>
            </IonList>
        );
    }
}