import { Stomp as stx } from '../stomp';

export default function generateModeratorActor() {
    var smqModerator = {
    };
    
    smqModerator.defer = function() {
        var deferred = {
            promise: null,
            resolve: null,
            reject: null
        };
        
        deferred.promise = new Promise((resolve, reject) => {
            deferred.resolve = resolve;
            deferred.reject = reject;
        });
        
        return deferred;
    }

    smqModerator.connect = function (virtualHost, username, password, on_received, after_connect) {
        console.warn('set `smqModerator.showPingPongs = true` to get verbose logging.');
        smqModerator.virtualHost = virtualHost;
        smqModerator.username = username;
        smqModerator.password = password;
        smqModerator.rabbitEndpoint = smqModerator.rabbitEndpoint || window.rabbitEndpoint || 'ws://sassymq.anabstractlevel.com:15674/ws';
        smqModerator.Moderator_all_connection = {};
        smqModerator.messages = [];
        smqModerator.waitingReply = [];
        
        smqModerator.client = window.Stomp.client(smqModerator.rabbitEndpoint);

        smqModerator.client.debug = function (m, p) {
            if (((m == ">>> PING") || (m == "<<< PONG")) && !smqModerator.showPingPongs) return;
            else {
                if (m == "<<< ") m = "";
                let data = p || m || "STRING"; 
                let indexOfContentLength = data.indexOf("content-length:");
                let dataStart = data.indexOf("\n\n");
                if ((dataStart > indexOfContentLength) && (indexOfContentLength > 1)) {
                    data = (data.substring(dataStart, data.length - 1) || '');
                    if (data.startsWith('"')) data = data.substring(1);
                    if (data.endsWith('"')) data = data.substring(0, data.length - 1);
                    data = data.substring(data.indexOf('{'));
                    data = data.substring(0, data.lastIndexOf('}') + 1);
                    try {
                        data = JSON.parse(data);
                        if (data.AccessToken) data.AccessToken = 'ay_******xyz';
                    } catch(ex) {
                        console.error('ERROR PARSING DATA for debug output', ex, data);
                    }
                    m = m.substring(0, m.indexOf('\n\n'));
                }
                console.log('CREATED: ' + this.createdAt + ' - ', m, data || p); 
            }
        }

        smqModerator.checkMessage = function(msg) {
            
                if (smqModerator.onModeratorDiscussionUpdated) {
                    if (msg.headers && (msg.headers.destination.includes('moderator.custom.moderator.discussionupdated'))) {
                        var rpayload = smqModerator.onModeratorDiscussionUpdated(msg.body, msg);
                        if (rpayload) smqModerator.sendReply(rpayload, msg);
                    }
                }
            
               
        }

        var on_connect = function (x) {
            smqModerator.Moderator_all_connection = smqModerator.client.subscribe("/exchange/moderator.all/#",
                    function (d) {
                        if (d.body) d.body = JSON.parse(d.body);
                        smqModerator.messages.push(d);
                        smqModerator.checkMessage(d);
                        if (on_received) on_received(d);
                        if (smqModerator.showPingPongs) console.log('      --------  MESSAGE FOR smqModerator: ', d);
                    }, {
                        durable: false,
                        requeue: true
                    });
            smqModerator.client.onreceive =  function (d) {
                        var body = JSON.parse(d.body);
                        var corrID = d.headers["correlation-id"];
                        var waitingDeferred = smqModerator.waitingReply[corrID];

                        if (waitingDeferred && body.IsHandled) {
                            delete smqModerator.waitingReply[corrID];
                            if (body && body.ErrorMessage) console.error("ERROR RECEIVED: " + body.ErrorMessage);
                            waitingDeferred.resolve(body, d);
                        }
                    };
                    if (after_connect) after_connect(x);
                };

        var on_error = function (frame) {
            frame = frame || { 'error': 'unspecified error' };
            console.error('ERROR On STOMP Client: ', frame.error, frame);
        };

        console.log('connecting to: ' + smqModerator.rabbitEndpoint + ', using ' + username + '/' + password);
        smqModerator.client.connect(smqModerator.username, smqModerator.password, on_connect, on_error, smqModerator.virtualHost);
    };

    smqModerator.disconnect = function() {
        if (smqModerator && smqModerator.client) 
        {
            smqModerator.client.disconnect();
        }
    }

    smqModerator.stringifyValue = function(value) {
        if (!value) value = '{}';
            if (typeof value == 'object') {
                value = JSON.stringify(value);
            }
        return value;
    };
    
    smqModerator.sendReply = function(replyPayload, msg) {
        if (replyPayload && msg && msg.headers && msg.headers['reply-to']) {
            replyPayload.IsHandled = true;
            smqModerator.client.send(msg.headers['reply-to'], 
                        { "content-type": "application/json", 
                          "reply-to":"/temp-queue/response-queue", 
                          "correlation-id":msg.headers['correlation-id'] 
                        }, JSON.stringify(replyPayload));
        }
    };

    
        
        smqModerator.waitFor = function (id) {
            setTimeout(function () {
                var waiting = smqModerator.waitingReply[id];
                if (waiting) {
                    waiting.reject("Timed out waiting for reply");
                }
            }, 30000)
        }
        
        smqModerator.createUUID = function() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }


        
        smqModerator.DiscussionUpdated = function() {
            smqModerator.DiscussionUpdated('{}');
        }

        smqModerator.DiscussionUpdated = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Discussion Updated - ');
            smqModerator.client.send('/exchange/moderatormic/moderator.custom.moderator.discussionupdated', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddDiscussion = function() {
            smqModerator.AddDiscussion('{}');
        }

        smqModerator.AddDiscussion = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Discussion - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.adddiscussion', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetDiscussions = function() {
            smqModerator.GetDiscussions('{}');
        }

        smqModerator.GetDiscussions = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Discussions - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getdiscussions', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateDiscussion = function() {
            smqModerator.UpdateDiscussion('{}');
        }

        smqModerator.UpdateDiscussion = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Discussion - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatediscussion', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddSubGroupOwner = function() {
            smqModerator.AddSubGroupOwner('{}');
        }

        smqModerator.AddSubGroupOwner = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Sub Group Owner - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addsubgroupowner', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetSubGroupOwners = function() {
            smqModerator.GetSubGroupOwners('{}');
        }

        smqModerator.GetSubGroupOwners = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Sub Group Owners - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getsubgroupowners', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateSubGroupOwner = function() {
            smqModerator.UpdateSubGroupOwner('{}');
        }

        smqModerator.UpdateSubGroupOwner = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Sub Group Owner - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatesubgroupowner', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteSubGroupOwner = function() {
            smqModerator.DeleteSubGroupOwner('{}');
        }

        smqModerator.DeleteSubGroupOwner = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Sub Group Owner - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deletesubgroupowner', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddOrganization = function() {
            smqModerator.AddOrganization('{}');
        }

        smqModerator.AddOrganization = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Organization - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addorganization', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetOrganizations = function() {
            smqModerator.GetOrganizations('{}');
        }

        smqModerator.GetOrganizations = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Organizations - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getorganizations', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateOrganization = function() {
            smqModerator.UpdateOrganization('{}');
        }

        smqModerator.UpdateOrganization = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Organization - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updateorganization', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteOrganization = function() {
            smqModerator.DeleteOrganization('{}');
        }

        smqModerator.DeleteOrganization = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Organization - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deleteorganization', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetFallacies = function() {
            smqModerator.GetFallacies('{}');
        }

        smqModerator.GetFallacies = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Fallacies - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getfallacies', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddTopicAgreement = function() {
            smqModerator.AddTopicAgreement('{}');
        }

        smqModerator.AddTopicAgreement = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Topic Agreement - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addtopicagreement', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetTopicAgreements = function() {
            smqModerator.GetTopicAgreements('{}');
        }

        smqModerator.GetTopicAgreements = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Topic Agreements - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.gettopicagreements', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateTopicAgreement = function() {
            smqModerator.UpdateTopicAgreement('{}');
        }

        smqModerator.UpdateTopicAgreement = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Topic Agreement - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatetopicagreement', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteTopicAgreement = function() {
            smqModerator.DeleteTopicAgreement('{}');
        }

        smqModerator.DeleteTopicAgreement = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Topic Agreement - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deletetopicagreement', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetOrganizationTypes = function() {
            smqModerator.GetOrganizationTypes('{}');
        }

        smqModerator.GetOrganizationTypes = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Organization Types - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getorganizationtypes', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetPeople = function() {
            smqModerator.GetPeople('{}');
        }

        smqModerator.GetPeople = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get People - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getpeople', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddOrganizationGroup = function() {
            smqModerator.AddOrganizationGroup('{}');
        }

        smqModerator.AddOrganizationGroup = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Organization Group - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addorganizationgroup', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetOrganizationGroups = function() {
            smqModerator.GetOrganizationGroups('{}');
        }

        smqModerator.GetOrganizationGroups = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Organization Groups - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getorganizationgroups', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateOrganizationGroup = function() {
            smqModerator.UpdateOrganizationGroup('{}');
        }

        smqModerator.UpdateOrganizationGroup = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Organization Group - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updateorganizationgroup', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteOrganizationGroup = function() {
            smqModerator.DeleteOrganizationGroup('{}');
        }

        smqModerator.DeleteOrganizationGroup = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Organization Group - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deleteorganizationgroup', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddTopicFallacy = function() {
            smqModerator.AddTopicFallacy('{}');
        }

        smqModerator.AddTopicFallacy = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Topic Fallacy - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addtopicfallacy', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetTopicFallacies = function() {
            smqModerator.GetTopicFallacies('{}');
        }

        smqModerator.GetTopicFallacies = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Topic Fallacies - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.gettopicfallacies', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateTopicFallacy = function() {
            smqModerator.UpdateTopicFallacy('{}');
        }

        smqModerator.UpdateTopicFallacy = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Topic Fallacy - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatetopicfallacy', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteTopicFallacy = function() {
            smqModerator.DeleteTopicFallacy('{}');
        }

        smqModerator.DeleteTopicFallacy = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Topic Fallacy - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deletetopicfallacy', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddOrganizationUser = function() {
            smqModerator.AddOrganizationUser('{}');
        }

        smqModerator.AddOrganizationUser = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Organization User - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addorganizationuser', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetOrganizationUsers = function() {
            smqModerator.GetOrganizationUsers('{}');
        }

        smqModerator.GetOrganizationUsers = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Organization Users - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getorganizationusers', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateOrganizationUser = function() {
            smqModerator.UpdateOrganizationUser('{}');
        }

        smqModerator.UpdateOrganizationUser = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Organization User - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updateorganizationuser', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteOrganizationUser = function() {
            smqModerator.DeleteOrganizationUser('{}');
        }

        smqModerator.DeleteOrganizationUser = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Organization User - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deleteorganizationuser', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddDiscussionParticipant = function() {
            smqModerator.AddDiscussionParticipant('{}');
        }

        smqModerator.AddDiscussionParticipant = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Discussion Participant - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.adddiscussionparticipant', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetDiscussionParticipants = function() {
            smqModerator.GetDiscussionParticipants('{}');
        }

        smqModerator.GetDiscussionParticipants = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Discussion Participants - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getdiscussionparticipants', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateDiscussionParticipant = function() {
            smqModerator.UpdateDiscussionParticipant('{}');
        }

        smqModerator.UpdateDiscussionParticipant = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Discussion Participant - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatediscussionparticipant', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteDiscussionParticipant = function() {
            smqModerator.DeleteDiscussionParticipant('{}');
        }

        smqModerator.DeleteDiscussionParticipant = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Discussion Participant - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deletediscussionparticipant', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddDiscussionTopic = function() {
            smqModerator.AddDiscussionTopic('{}');
        }

        smqModerator.AddDiscussionTopic = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Discussion Topic - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.adddiscussiontopic', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetDiscussionTopics = function() {
            smqModerator.GetDiscussionTopics('{}');
        }

        smqModerator.GetDiscussionTopics = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Discussion Topics - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getdiscussiontopics', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateDiscussionTopic = function() {
            smqModerator.UpdateDiscussionTopic('{}');
        }

        smqModerator.UpdateDiscussionTopic = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Discussion Topic - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatediscussiontopic', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteDiscussionTopic = function() {
            smqModerator.DeleteDiscussionTopic('{}');
        }

        smqModerator.DeleteDiscussionTopic = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Discussion Topic - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deletediscussiontopic', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.AddSubGroup = function() {
            smqModerator.AddSubGroup('{}');
        }

        smqModerator.AddSubGroup = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Add Sub Group - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.addsubgroup', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.GetSubGroups = function() {
            smqModerator.GetSubGroups('{}');
        }

        smqModerator.GetSubGroups = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Get Sub Groups - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.getsubgroups', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.UpdateSubGroup = function() {
            smqModerator.UpdateSubGroup('{}');
        }

        smqModerator.UpdateSubGroup = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Update Sub Group - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.updatesubgroup', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        
        smqModerator.DeleteSubGroup = function() {
            smqModerator.DeleteSubGroup('{}');
        }

        smqModerator.DeleteSubGroup = function(payload) {
            payload = smqModerator.stringifyValue(payload);
            var id = smqModerator.createUUID();
            var deferred = smqModerator.waitingReply[id] = smqModerator.defer();
            if (smqModerator.showPingPongs) console.log('Delete Sub Group - ');
            smqModerator.client.send('/exchange/moderatormic/crudcoordinator.crud.moderator.deletesubgroup', { "content-type": "text/plain", "reply-to":"/temp-queue/response-queue", "correlation-id":id }, payload);
            
            smqModerator.waitFor(id);
            
            return deferred.promise;
        }
        

    return smqModerator;
}

                    