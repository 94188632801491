
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { thumbsDownOutline, thumbsUpOutline, thumbsUpSharp, addOutline, chevronBackOutline } from "ionicons/icons";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import OrganizationHeader from '../organizations/OrganizationHeader';

export default class OrganizationGroupComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            organizationGroup: undefined,
            dataReady: false
        };
    }

    async reloadOrganizationGroup() {
        if(this.state.organizationGroup != undefined) this.setState({ organizationGroup: undefined });

        if (!this.state.reloadCalled) this.setState({ reloadCalled: true });
        let payload = this.context.createPayload()
        payload.AirtableWhere = "ShortName='" + this.props.match.params.groupCode + "'";
        var reply = await this.context.moderator.GetOrganizationGroups(payload);
        if (this.hasNoErrors(reply) && reply.OrganizationGroups && reply.OrganizationGroups.length) {
            console.error('GOT REPLY TO ORG GROUP REQUEST: ', reply);
            var organizationGroup = reply.OrganizationGroups[0];
            payload.AirtableWhere = `OrganizationGroup='${organizationGroup.Name}'`;
            reply = await this.context.moderator.GetSubGroups(payload);
            if (this.hasNoErrors(reply)) {
                organizationGroup.SubGroups = reply.SubGroups;
            }

            var newState = { organizationGroup: organizationGroup, reloadRequested: true }
            this.setState(newState);
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        if (this.state.isReady &&
            (!this.state.reloadCalled || (this.state.organizationGroup && (this.state.organizationGroup.ShortName != this.props.match.params.groupCode)))) {
            this.reloadOrganizationGroup();
        }
    }


    render() {
        console.error('rendering');
        const { organizationGroup } = this.state;
        return (
            <IonPage>
                {!organizationGroup ? <IonLoading isOpen={true} message={'Loading...'} /> : <>

                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonMenuButton />
                            </IonButtons>
                            <div style={{ float: 'right' }}>
                                <button onClick={() => this.reloadOrganizationGroup()}>Reload</button>
                            </div>
                            <OrganizationHeader organizationLogo={organizationGroup?.OrganizationLogo}
                                name={organizationGroup?.OrganizationName || "..."}
                                subName={organizationGroup?.DisplayName || '... loading ...'} />
                        </IonToolbar>
                    </IonHeader>

                    <IonContent fullscreen>
                        <div>
                            <div>
                                <IonButton routerLink={"/org/" + (organizationGroup?.OrganizationShortName)}
                                    style={{ float: 'right' }}>
                                    <IonIcon icon={chevronBackOutline}></IonIcon> {organizationGroup?.OrganizationName || "..."}
                                </IonButton>
                                <div>
                                    {organizationGroup?.Notes && <div>
                                        <b>Description:</b>
                                        {organizationGroup?.Notes}
                                    </div>}
                                    {organizationGroup?.Attachments?.length ? <div>{organizationGroup?.Attachments[0].url}<img src={organizationGroup?.Attachments[0].url} style={{ width: '3em', float: 'left' }} /></div> : undefined}
                                    {organizationGroup?.Description}
                                </div>

                                <h3>{organizationGroup?.SubGroups?.length} {organizationGroup?.PluralSubGroupDisplayName}</h3>
                                {organizationGroup?.SubGroups
                                    ?.sort((a: any, b: any) => a.SubGroupNumber > b.SubGroupNumber ? -1 : 1)
                                    .map((subGroup: any) => {
                                        return <div key={subGroup.SubGroupId} style={{ clear: 'both' }} className="listItem">
                                            <div style={{ float: 'right' }}>
                                                {subGroup.SubGroupDiscussionCount ? subGroup.SubGroupDiscussionCount + ' ' + organizationGroup?.PluralDiscussionDisplayName : '--'}/
                                                {subGroup.SubGroupOwners?.length ? subGroup.SubGroupOwners?.length + ' ' + organizationGroup?.PluralSubGroupOwnerDisplayName : '--'}
                                                
                                                </div>
                                            <IonButton routerLink={"/sub/" + subGroup.ShortName} style={{ float: 'left' }}> {subGroup?.DisplayName}</IonButton>
                                        </div>
                                    })}
                                <IonButton onClick={() => this.addSubGroup()} color="success"><IonIcon icon={addOutline}></IonIcon> Add {organizationGroup?.SubGroupDisplayName}</IonButton>
                            </div>

                        </div>
                    </IonContent>
                </>}
            </IonPage>

        );
    }
    async addSubGroup() {
        var payload = this.context.createPayload();
        payload.SubGroup = {
            OrganizationGroup: this.state.organizationGroup.OrganizationGroupId,
            SubGroupNumber: this.state.organizationGroup.NextSubGroupNumber
        }
        var reply = await this.context.moderator.AddSubGroup(payload);
        if (this.hasNoErrors(reply)) {
            window.location.href = '/sub/' + reply.SubGroup.ShortName;
        }
    }
}