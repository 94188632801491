
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { addOutline, pencilOutline } from "ionicons/icons";

export default class OrganizationComponent extends EffortlessBaseComponent {
    didMount: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            organization: undefined,
            reloadCalled: false,
        };
    }

    async reloadOrganization() {
        if (this.state.organization != undefined) this.setState({ organization: undefined });

        if (!this.state.reloadCalled) this.setState({ reloadCalled: true });
        let payload = this.context.createPayload()
        payload.AirtableWhere = "ShortName='" + this.props.match.params.orgCode + "'";
        var reply = await this.context.moderator.GetOrganizations(payload);
        if (this.hasNoErrors(reply) && reply.Organizations && reply.Organizations.length) {
            var organization = reply.Organizations[0];
            console.error('GOT ORG: ', organization);
            payload.AirtableWhere = `OrganizationName='${organization.Name.replace(/\'/g, "\\'")}'`;
            reply = await this.context.moderator.GetOrganizationGroups(payload);
            if (this.hasNoErrors(reply)) {
                organization.Groups = reply.OrganizationGroups;
                payload.AirtableWhere = `Organization='${organization.Name.replace(/\'/g, "\\'")}'`;
                reply = await this.context.moderator.GetOrganizationUsers(payload);
                if (this.hasNoErrors(reply)) {
                    organization.OrganizationUsers = reply.OrganizationUsers;
                    var newState = { organization: organization, reloadCalled: true };
                    this.setState(newState);
                }
            }
        } else {
            console.error('ERRO LOADING ORG: ', reply.ErrorMessage);
        }
    }

    async addOrganizationGroup() {
        var payload = this.context.createPayload();
        payload.OrganizationGroup = {
            Organization: this.state.organization.OrganizationId,
            GroupNumber: this.state.organization.NextGroupNumber
        }
        var reply = await this.context.moderator.AddOrganizationGroup(payload)
        console.error('ADDED ORGANIZTION: ', reply);
        if (this.hasNoErrors(reply)) {
            var organizationGroup = reply.OrganizationGroup;
            this.state.organization.Groups.push(organizationGroup);
            this.state.organization.CurrentOrganizationGroup = reply.OrganizationGroup.OrganizationGroupId;
            payload = this.context.createPayload();
            payload.Organization = JSON.parse(JSON.stringify(this.state.organization));
            delete payload.Organization.OrganizationGroups;
            reply = await this.context.moderator.UpdateOrganization(payload);
            console.error('UPDATED SHOW WITH LATEST GROUP INFO');
            if (this.hasNoErrors(reply)) {
                reply.Organization.Groups = this.state.organization.Groups;
                this.setState({ organization: reply.Organization });
            }
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        console.error('Checking Organization: ', this.state)
        if (this.state.isReady &&
            (!this.state.reloadCalled || (this.state.organization && (this.state.organization.ShortName != this.props.match.params.orgCode)))) {
            this.reloadOrganization();

        }
    }


    render() {
        console.error('rendering');
        const { organization } = this.state;
        return (
            <IonPage>
                {!organization ?
                    <IonLoading isOpen={true} message={'Loading...'} />
                    :
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonMenuButton />
                                </IonButtons>
                                <div style={{ float: 'right' }}>
                                    <button onClick={() => this.reloadOrganization()}>Reload</button>
                                </div>
                                <IonTitle>{organization?.Name || 'Loading organization...'}
                                    <IonButton size="small" color="light" routerLink={"/org/" + organization?.ShortName + '/edit'}><IonIcon icon={pencilOutline}></IonIcon></IonButton>
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent fullscreen>
                            <div>
                                <div>
                                    {organization && <IonButton color="success" onClick={() => this.addOrganizationGroup()} style={{ float: 'right' }}>
                                        <IonIcon icon={addOutline}></IonIcon> Add {organization?.OrganizationGroupDisplayName} {(this.state?.organization?.CurrentGroupNumber || 0) + 1}
                                    </IonButton>}
                                    {organization?.Attachments?.length ? <div><img src={organization?.Attachments[0].url} style={{ width: '6em', float: 'left' }} /></div> : undefined}
                                    <h3>{organization?.Groups?.length} {organization?.PluralGroupDisplayName} </h3>
                                    <div>
                                        <b>Description: </b>
                                    </div>
                                    <div>
                                        {organization?.Notes || 'no description yet...'}
                                    </div>
                                    <div style={{ clear: 'both' }}>
                                        {organization?.Groups
                                            ?.sort((a: any, b: any) => a.GroupNumber > b.GroupNumber ? -1 : 1)
                                            .map((orgGroup: any) => {
                                                return <div key={orgGroup.Name} className="listItem">
                                                    <div style={{ float: 'right' }}>
                                                        {orgGroup.SubGroups?.length || "0"} {orgGroup.SubGroups?.length == 1 ? organization?.SubGroupDisplayName : organization?.PluralSubGroupDisplayName}
                                                    </div>
                                                    <IonButton routerLink={"/group/" + orgGroup.ShortName} style={{ float: 'left' }}> {orgGroup?.DisplayName}</IonButton>
                                                </div>
                                            })}
                                    </div>
                                    <div style={{ clear: 'both' }}>
                                        <h3>{organization?.OrganizationUsers?.length} Organization Users</h3>
                                        {organization?.OrganizationUsers
                                            ?.sort((a: any, b: any) => a.Name > b.Name ? -1 : 1)
                                            .map((orgUser: any) => {
                                                return <div key={orgUser.Name} className="listItem">
                                                    <div style={{ float: 'right' }}>
                                                        {orgUser.SubGroups?.length || "0"} {orgUser.SubGroups?.length == 1 ? organization?.SubGroupDisplayName : organization?.PluralSubGroupDisplayName}
                                                    </div>
                                                    <IonButton routerLink={"/group/" + orgUser.ShortName} style={{ float: 'left' }}> {orgUser?.DisplayName}</IonButton>
                                                </div>
                                            })}

                                        <div>
                                            <h4>Add User</h4>
                                            <div>
                                                <label>Invite user to your organization...</label>
                                                <input value={this.state.inviteEmailAddress} onChange={(e:any) => this.setState({inviteEmailAddress:e.target.value})} placeholder="bob@domain.com" />
                                            </div>
                                            <IonButton onClick={(e:any) => this.addUser(e)}>Add User</IonButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IonContent>
                    </>}
            </IonPage>
        );
    }
    addUser(e:any): void {
        console.error('Adding User: ', e, this.state.inviteEmailAddress);
    }
}