import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonItemGroup, IonItemOption, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import AddMediator from './addOrgComponents/AddMediator';
import AddCallInShow from './addOrgComponents/AddCallInShow';
import AddCompany from './addOrgComponents/AddCompany';
import AddRandomThoughts from './addOrgComponents/AddRandomThoughts';
import AddCouplesCouncelor from './addOrgComponents/AddCouplesCouncelor'
import OrganizationTypeWidget from './OrganizationTypeWidget'
export default class AddOrganizationComponent extends EffortlessBaseComponent {
    constructor(props: any) {
        super(props)
        this.state = {
            organizationTypeName: "Mediators",
            newOrg: {}
        }
    }

    async setupDefaultUserOrganization(e: any) {
        e.preventDefault();
        await this.context.setupDefaultUserOrganization();
        this.props.history.push('/organizations');
    }

    addOrganization(e : any) {
        console.error('ADD ORGANIZTION....');
    }

    componentDidUpdate() {
        if (!this.state.newOrg.OrganizationType) {
            this.setOrgTypeName(this.state.organizationTypeName)
        }
    }

    updateOrganizationType(e: any) {        
        let orgTypeName = e.target.value;
        this.setOrgTypeName(orgTypeName);
    }

    private setOrgTypeName(orgTypeName: any) {
        console.error('SETTING ORG TYPE NAME: ', orgTypeName, this.context.organizationTypesByName);
        if (this.context.organizationTypesByName) {            
            this.state.newOrg.OrganizationType = this.context.organizationTypesByName[orgTypeName].OrganizationTypeId;
            console.error('NEW ORG SET: ', this.state.newOrg);
            this.setState({ organizationTypeName: orgTypeName, newOrg: this.state.newOrg });
        }
    }

    render() {
        return (
            <IonPage>
                {this.context.whoAmI ? 
                <IonGrid style={{margin:0}}>
                    <IonRow>
                        <IonCol size="10" offset="0">
                            <IonItemGroup>
                                <IonItem>
                                    <IonLabel>What kind of organization is it?</IonLabel>
                                    <IonSelect value={this.state.organizationTypeName} 
                                                onIonChange={(e:any) => this.updateOrganizationType(e)} >
                                        {this.context.organizationTypes?.map((organizationType:any) => 
                                            <IonSelectOption value={organizationType.Name}>
                                                {organizationType.PluralDisplayName}
                                            </IonSelectOption>)}
                                    </IonSelect>
                                </IonItem>
                                <IonItem style={{padding: '1em'}}>
                                    <OrganizationTypeWidget organizationTypeName={this.state.organizationTypeName}></OrganizationTypeWidget>
                                </IonItem>
                                {this.state.organizationTypeName == "Mediators" && 
                                    <AddMediator newOrg={this.state.newOrg} orgUpdated={(newOrg:any) => this.setState({newOrg: newOrg})}></AddMediator>}
                                {this.state.organizationTypeName == "CallInShows" && 
                                    <AddCallInShow newOrg={this.state.newOrg} orgUpdated={(newOrg:any) => this.setState({newOrg: newOrg})}></AddCallInShow>}
                                {this.state.organizationTypeName == "CompanyProjects" && 
                                    <AddCompany newOrg={this.state.newOrg} orgUpdated={(newOrg:any) => this.setState({newOrg: newOrg})}></AddCompany>}
                                {this.state.organizationTypeName == "RandomThoughts" && 
                                    <AddRandomThoughts newOrg={this.state.newOrg} orgUpdated={(newOrg:any) => this.setState({newOrg: newOrg})}></AddRandomThoughts>}
                                {this.state.organizationTypeName == "CouplesCouncelors" && 
                                    <AddCouplesCouncelor newOrg={this.state.newOrg} orgUpdated={(newOrg:any) => this.setState({newOrg: newOrg})}></AddCouplesCouncelor>}
                            </IonItemGroup>
                            {!this.context.whoAmI?.OrganizationUser?.length && 
                            <IonItem lines="none">
                                <IonItem lines="none">
                                    <IonLabel>OR </IonLabel>
                                </IonItem>

                                <IonButton color="warning" onClick={(e: any) => this.setupDefaultUserOrganization(e)}>Create default organization</IonButton>
                            </IonItem>}
                        </IonCol>
                    </IonRow>
                </IonGrid> :
                <IonItem>
                    Loading....
                </IonItem>}
            </IonPage>
        );
    }
    
}