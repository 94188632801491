import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
} from '@ionic/react';


import { useLocation } from 'react-router-dom';
import { menuController } from '@ionic/core';
import {
  archiveOutline, archiveSharp, bookmarkOutline, flagOutline, flagSharp, heartOutline, heartSharp, homeOutline, homeSharp, logInOutline, logInSharp, logoFacebook, logOutOutline, logOutSharp, mailOutline, mailSharp,
  paperPlaneOutline, paperPlaneSharp, printOutline, printSharp, trashOutline, trashSharp, warningOutline, warningSharp
} from 'ionicons/icons';
import './Menu.css';
import { useControlled } from '@material-ui/core';
import { useContext } from 'react';
// import { GDS } from "../services/gds.service";


interface AppPage {
  isAuthenticated: boolean;
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/about',
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    isAuthenticated: false
  },
  {
    title: 'Organizations',
    url: '/organizations',
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    isAuthenticated: true
  },
  // {
  //   title: 'People',
  //   url: '/people',
  //   iosIcon: heartOutline,
  //   mdIcon: heartSharp,
  //   isAuthenticated: true
  // },
  // {
  //   title: 'Moderators',
  //   url: '/moderators',
  //   iosIcon: archiveOutline,
  //   mdIcon: archiveSharp,
  //   isAuthenticated: true
  // },
  {
    title: 'Profile',
    url: '/account',
    iosIcon: warningOutline,
    mdIcon: warningSharp,
    isAuthenticated: true
  },
  {
    title: 'D3',
    url: '/d3',
    iosIcon: printOutline,
    mdIcon: printSharp,
    isAuthenticated: true
  },
  {
    title: 'Login',
    url: '/login',
    iosIcon: logInOutline,
    mdIcon: logInSharp,
    isAuthenticated: false
  },
  {
    title: 'Register',
    url: '/register',
    iosIcon: flagOutline,
    mdIcon: flagSharp,
    isAuthenticated: false
  },
  {
    title: 'Log Out',
    url: '/logout',
    iosIcon: logOutOutline,
    mdIcon: logOutSharp,
    isAuthenticated: true
  }

];


const Menu: React.FC = () => {
  const location = useLocation();
  const context = useContext(window.GDS);
  // console.error("Route: ", location.pathname)
  // menuController.enable(true, "main")

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>E.M.M.A. Mediates</IonListHeader>
          <IonNote>Welcome {window.GDS?.whoAmI?.EmailAddress || 'Guest'}</IonNote>
          {appPages.map((appPage, index) =>
            <IonMenuToggle key={index} autoHide={false}>
              {((!appPage.isAuthenticated && !window.GDS.accessToken) || (appPage.isAuthenticated && window.GDS.accessToken)) &&
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url}
                  routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>}
            </IonMenuToggle>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
