import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { EffortlessBaseComponent } from '../../../services/EffortlessBaseComponent';

export default class AddOrgBaseComponent extends EffortlessBaseComponent {    

    constructor(props : any) {
        super(props);        
    }


    componentDidUpdate() {
        if (!this.state.newOrg) this.setState({newOrg:this.props.newOrg});
    }
    updateNewOrg(event: any, name: string): void {   
        this.state.newOrg[name] = event.target.value;
        this.setState({newOrg:this.state.newOrg});
        console.error('UPDATING NEW ORT')
        this.props.orgUpdated(this.state.newOrg);
    }

    addOrganization(e: any) {
        console.error('ADDING ORGANIZTION NOW: ', this.state.newOrg);
        this.context.addNewOrganization(this.state.newOrg.OrganizationType, this.state.newOrg.Name, this.state.newOrg.Name, this.state.newOrg.FirstGroup);
    }
}