import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { EffortlessBaseComponent } from '../../../services/EffortlessBaseComponent';
import AddOrgBase from './AddOrgBase';

export default class AddMediatorComponent extends AddOrgBase {

    render() {
        return <div>
            <h3>Add Mediator</h3>
            <IonItem>
                <IonLabel>Organization Name: </IonLabel>
                <IonInput value={this.state.newOrg?.Name} 
                            onIonChange={(event) => this.updateNewOrg(event, 'Name')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Client: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstOrgGroup} 
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstOrgGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>1st Issue: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstSubGroup} 
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstSubGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Discusion: </IonLabel>
                <IonInput value={this.state.newOrg?.FirstDiscussion} 
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstDiscussion')} >
                </IonInput>
            </IonItem>

            <IonItem lines="none">
                <IonButton onClick={(e: any) => this.addOrganization(e)}>Create Mediation Organization</IonButton>
            </IonItem>

        </div>
    }
}