
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { curveNatural } from "d3";

export default class AddDiscussionComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            subGroup: undefined,
            subject : "",   
            guestName : ""
        };

        this.reloadSubGroup = this.reloadSubGroup.bind(this);
        this.subjectChanged = this.subjectChanged.bind(this);
        this.guestNameChanged = this.guestNameChanged.bind(this);
        this.addDiscussion = this.addDiscussion.bind(this);
    }


    async reloadSubGroup() {
        if (!this.state.reloadCalled) this.setState({reloadCalled: true});
        else {
            let payload = this.context.createPayload()
            payload.AirtableWhere = "ShortName='" + this.props.match.params.subGroupCode + "'";
            var reply = await this.context.moderator.GetSubGroups(payload);
            if (this.hasNoErrors(reply) && reply.SubGroups && reply.SubGroups.length) {
                var subGroup = reply.SubGroups[0];
                var newState = { subGroup: subGroup }
                this.setState(newState);
            }
        }
    }

    subjectChanged(event : any) {
        this.setState({subject: event.target.value});
    }

    guestNameChanged(event : any) {
        this.setState({guestName: event.target.value});
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        if (this.state.isReady && (!this.state.reloadCalled || (this.state.subGroup?.ShortName != this.props.match.params.subGroupCode))) {
            this.reloadSubGroup();
        }
    }

    async addDiscussion() {
        console.error('Adding discussion', this.state.subject, this.state.subGroup);
        var payload = this.context.createPayload();
        payload.Discussion = {
            SubGroup: this.state.subGroup.SubGroupId,
            Subject : this.state.subject
        };
        var reply = await this.context.moderator.AddDiscussion(payload);
        // add hosts and player
        if (this.hasNoErrors(reply)) {
            var discussion = reply.Discussion;
            payload.AirtableWhere = `SubGroup='${discussion.SubGroupName}'`
            var reply = await this.context.moderator.GetSubGroupOwners(payload)
            if (this.hasNoErrors(reply)) {
                reply.SubGroupOwners.forEach(async (owner : any) => {
                    console.error('adding sub group owner: ', owner)
                    let chosenName = owner.DisplayName;
                    let organizationUserId = owner.Owner;
                    let role = owner.Role;
                    await this.AddDiscussionParticipant(discussion, chosenName, organizationUserId, role);
                });
            }
            if (this.state.guestName) {
                this.AddDiscussionParticipant(discussion, this.state.guestName, null, "Caller");
            }
            payload.DiscussionTopic = {
                Discussion: discussion.DiscussionId,
                Subject : discussion.Subject
            };
            reply = await this.context.moderator.AddDiscussionTopic(payload);
            if (this.hasNoErrors(reply)) {
                discussion.Topics = [reply.DiscussionTopic];
                discussion.DiscussionTopics = [reply.DiscussionTopic.DiscussionTopicId];
                //window.location.href = '/discussion/' + discussion?.ShortName;
            }
        }
    }


    private async AddDiscussionParticipant(discussion: any, chosenName: any, organizationUserId: any, role: any) {
        var payload = this.context.createPayload();
        payload.DiscussionParticipant = {
            Discussion: discussion.DiscussionId,
            ChosenName: chosenName,
            OrganizationUser: organizationUserId,
            Role: role || 'Host'
        };
        var hostReply = await this.context.moderator.AddDiscussionParticipant(payload);
        if (this.hasNoErrors(hostReply)) {
            var participant = hostReply.DiscussionParticipant;
            console.error('ADDING PARTICIPANT: ', participant);
            discussion.Participants = discussion.Participants || [];
            discussion.Participants.push(participant);
            discussion.DiscussionParticipants = discussion.DiscussionParticipants || [];
            discussion.DiscussionParticipants.push(participant.DiscussionlParticipantId);
        }
        return payload;
    }

    render() {
        console.error('rendering');
        const { subGroup } = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>{subGroup?.Name || 'Loading ...'}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div>
                        <IonButton routerLink={"/sub/" + subGroup?.ShortName}>{subGroup?.ShortName}</IonButton>
                        <div style={{float: 'right'}}>
                            <button onClick={this.reloadSubGroup}>Reload</button>
                        </div>
                        <div>
                            Add a Discussion
                        </div>
                        <input value={this.state.subject} onChange={this.subjectChanged} />
                        <div>
                            Guest
                        </div>
                        <input value={this.state.guestName} onChange={this.guestNameChanged} />
                    </div>

                    <div>
                        <IonButton onClick={this.addDiscussion} disabled={this.state.subject.length < 4}>Add</IonButton>
                    </div>
                </IonContent>
            </IonPage>

        );
    }
}