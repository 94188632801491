
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { addOutline, pencilOutline } from "ionicons/icons";

export default class OrganizationHeaderComponent extends EffortlessBaseComponent {
    didMount: boolean = false;

    constructor(props: any) {
        super(props);

    }


    render() {
        const { organizationLogo, name, subName } = this.props;
        return (
            <div>
                <div>
                    {organizationLogo?.length && 
                    <img src={organizationLogo[0].url} style={{width: '3em', float: 'left'}} />}
                    <h3>{name} - {subName} </h3>
                </div>
            </div>
        );
    }

}