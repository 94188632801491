import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { EffortlessBaseComponent } from "../services/EffortlessBaseComponent";

export default class HomeComponent extends EffortlessBaseComponent {
    constructor(props: any) {
        super(props);
    }
    componentDidUpdate() {
        this.isAuthenticated = false;
        super.componentDidUpdate();
    }

    render() {
        return <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>About EMMA</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ padding: '1em' }}>
                    <b>Getting started:</b>
                    <p>
                        E.M.M.A/ stands for Epistemological Modeling and Mediation App, and helps
                        people that want to have honest, but difficult discussions, more easily.
                    </p>
                    <IonButton routerLink={"/register"}>Get Started</IonButton>

                    <p>
                        Already a customer?
                    </p>
                    <IonButton routerLink={"/login"}>Sign in</IonButton>
                </div>
            </IonContent>
        </IonPage>
    }
}