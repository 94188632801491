import './Discussion.css'
import Popup from 'reactjs-popup'
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonPopover,
    IonRadio,
    IonRow,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonSlide,
    IonSlides,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import EditTopic from './EditTopic'
import TopicParticipant from './TopicParticipant'
import { NONAME } from 'dns';
import { threadId } from 'worker_threads';
import { addOutline, removeOutline, arrowForward, arrowDown, closeCircle } from 'ionicons/icons';
import { autoType, scaleDivergingSqrt } from 'd3';


export default class TopicComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            discussion: props.discussion,
            topic: props.topic,
            topicPopoverEvent: undefined,
            mouseOnTopicBtn: false,
            mouseOnTopicPopOver: false,
            showTopicPopover: false,
            editDlgOpen: false,
            addAgreementDlg: false,
            topicChanged: props.topicChanged,
            participantChanged: props.participantChanged,
            fallacyPopOverEvent: undefined,
            showFallacyPopOver: false,
            searchText: null,
            discussionparticipant: props.discussionparticipant,
            defaultFallcyIcon: "https://dl.airtable.com/.attachmentThumbnails/766cbe23fa5735be2285de185b82da5a/2e62967c"
        };
        this.handleClickToOpen = this.handleClickToOpen.bind(this);
        this.handleToClose = this.handleToClose.bind(this);
        this.handleToSave = this.handleToSave.bind(this);
        this.onTopicEnter = this.onTopicEnter.bind(this);
        this.onTopicLeave = this.onTopicLeave.bind(this);
        this.handleToDelete = this.handleToDelete.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showAgreementDlg = this.showAgreementDlg.bind(this);
        this.handleCloseAgreementDlg = this.handleCloseAgreementDlg.bind(this);
    }

    componentDidMount() {

    }

    handleClickToOpen() {
        delete this.state.topic.relatedTopicSubject;
        this.setState({ editDlgOpen: true });
    };

    handleToClose() {
        this.setState({ editDlgOpen: false });
    };

    handleCloseAgreementDlg() {
        this.setState({ addAgreementDlg: false });
    };

    async handleToSave() {
        console.error('TOPIC SAVED :: ', this.state.topic, this.state.topic.relatedTopicSubject, 'foo')
        if (this.state.topic.relatedTopicSubject) {
            this.state.topicChanged({ relatedTopicSubject: this.state.topic.relatedTopicSubject });
            this.setState({ editDlgOpen: false });
        } else {

            var payload = this.context.createPayload();
            payload.DiscussionTopic = this.state.topic;
            var reply = await this.context.moderator.UpdateDiscussionTopic(payload);
            if (this.hasNoErrors(reply)) {
                this.setState({ editDlgOpen: false, topic: payload.DiscussionTopic });
            }
        }
    }

    async handleToDelete() {
        console.error('DELETING TOPIC', this.state.topic)
        var payload = this.context.createPayload();
        payload.DiscussionTopic = this.state.topic;
        var reply = await this.context.moderator.DeleteDiscussionTopic(payload);
        if (this.hasNoErrors(reply)) {
            this.setState({ editDlgOpen: false, topic: payload.DiscussionTopic });
        }
    }

    onTopicEnter(event: any) {
        var topic = this.state.topic;
        topic.inHover = true;
        this.setState({ topic: topic })
    }

    onTopicLeave(event: any) {
        var topic = this.state.topic;
        topic.inHover = false;
        this.setState({ topic: topic })
    }

    async onChange(event: any) {
        this.state.topicChanged({ discussionTopicId: event.target.value });
    }

    showAgreementDlg() {
        this.setState({ addAgreementDlg: true });
    }

    async removeAgreement(agreement: any) {
        console.error('REMOVING AGREEMENT', agreement);
        var payload = this.context.createPayload();
        payload.TopicAgreement = agreement;
        var reply = await this.context.moderator.DeleteTopicAgreement(payload);
        if (this.hasNoErrors(reply)) {
            var index = this.state.discussion.Agreements.indexOf(agreement);
            if (index >= 0) this.state.discussion.Agreements.splice(index, 1);
            this.state.topicChanged({ discussionTopicId: this.state.discussion.CurrentTopic })
        }
    }
    async removeFallacy(fallacy: any) {
        console.error('REMOVING AGREEMENT', fallacy);
        var payload = this.context.createPayload();
        payload.TopicFallacy = fallacy;
        var reply = await this.context.moderator.DeleteTopicFallacy(payload);
        if (this.hasNoErrors(reply)) {
            var index = this.state.discussion.Fallacies.indexOf(fallacy);
            if (index >= 0) this.state.discussion.Fallacies.splice(index, 1);
            this.state.topicChanged({ discussionTopicId: this.state.discussion.CurrentTopic })
        }
    }
    getAgreementUrl(agreement: any) {
        return (agreement.DiscussionParticipantAvatar && agreement.DiscussionParticipantAvatar.length) ?
            agreement.DiscussionParticipantAvatar[0].url : '/assets/avatar.png';
    }

    getTopicUrl(topic: any) {
        return (topic.DiscussionParticipantAvatar && topic.DiscussionParticipantAvatar.length) ?
            topic.DiscussionParticipantAvatar[0].url : '/assets/avatar.png';
    }

    async relatedTopicSubjectChanged(event: any) {
        this.state.topic.relatedTopicSubject = event.target.value;
        this.setState({ topic: this.state.topic });
    }

    topicChanged(topic: any) {
        console.error('TOPIC CHANGED: ', topic);
        this.state.topicChanged({ discussionTopicId: topic.DiscussionTopicId });
    }

    keyPressed(event: any) {
        if (event?.code == 'Enter') {
            this.handleToSave();
            this.state.topic.relatedTopicSubject = "";

            this.setState({ topic: this.state.topic });
        }
    }

    async addFallacy(fallacy: any, status: any) {
        console.error('ADDING FALACY', this.state);
        await this.setFallacy(fallacy, status);
        this.setState({ showFallacyPopOver: false })
    }

    private async setFallacy(fallacy: any, status: string) {
        // console.error('Updating Agreement: ', status, this.state.discussion.Agreements);
        var existingFallacies = this.lookForExistingFallacy(fallacy);

        var existingFallacy = (existingFallacies && existingFallacies.length) ? existingFallacies[0] : null;
        var reply = null;

        if (existingFallacy) {
            reply = await this.updateExistingTopicFallacy(existingFallacy, status);
        } else {
            reply = await this.addTopicFallacy(fallacy, status);
        }

        if (this.hasNoErrors(reply)) {
            await this.state.discussion.Fallacies.push(reply.TopicFallacy);
        }
        this.state.topicChanged({ discussionTopicId: this.state.discussion.CurrentTopic });
    }

    async toggleCollapse(topic: any) {
        topic.IsCollapsed = !topic.IsCollapsed;
        var payload = this.context.createPayload();
        payload.DiscussionTopic = topic;
        var reply = await this.context.moderator.UpdateDiscussionTopic(payload);
        if (this.hasNoErrors(reply)) {
            this.setState({ topic: topic });
        }
    }

    private async addTopicFallacy(fallacy: any, status: string,) {
        var payload = this.context.createPayload();
        console.error('Adding new Fallacy');
        payload.TopicFallacy = payload.TopicFallacy || {
            DiscussionTopic: this.state.topic.DiscussionTopicId,
            Fallacy: fallacy.FallacyId
        };
        payload.TopicFallacy.Status = status;
        return await this.context.moderator.AddTopicFallacy(payload);

    }

    private async updateExistingTopicFallacy(existingFallacy: any, status: string) {
        var payload = this.context.createPayload();
        console.error('Updating existing Fallacy');
        var index = this.state.discussion.Fallacies.indexOf(existingFallacy);
        this.state.discussion.Fallacies.splice(index, 1);
        payload.TopicFallacies = existingFallacy;
        existingFallacy.Status = status;
        return await this.context.moderator.UpdateTopicFallacy(payload);

    }

    private lookForExistingFallacy(fallacy: any) {
        console.error("Fallacies::", this.state.discussion.Fallacies)

        return this.state.discussion.Fallacies.filter((topicFallacy: any) => (
            (topicFallacy?.DiscussionTopic == this.state.topic.DiscussionTopicId) &&
            (topicFallacy?.Fallacy == fallacy?.FallacyId)));
    }

    fallacyTabs(discussion: any, topic: any) {
        return (
            <>
                {discussion?.Fallacies?.filter((fallacy: any) => fallacy?.DiscussionTopic == topic.DiscussionTopicId).map((fallacy: any) =>
                    <IonChip outline key={fallacy?.TopicFallacyId} onClick={() => { this.removeFallacy(fallacy) }} title={this.getFallacyTitle(fallacy)}>
                        <IonAvatar>
                            <img style={{ width: "10em", verticalAlign: "middle", padding: "0.1em" }} src={fallacy.FallacyIcon ? fallacy.FallacyIcon[0].url : this.state.defaultFallcyIcon} />
                        </IonAvatar>
                        <IonIcon icon={closeCircle} />
                    </IonChip>

                )}
            </>
        );
    }
    getFallacyTitle(fallacy: any): string | undefined {
        return ' --- ' + fallacy.FallacyName + ' ---\r\n' + fallacy.FallacyDescription + '\r\n  e.g. ' + fallacy.FallacyExample;
    }

    render() {
        const { discussion, topic } = this.state;
        const childTopics = discussion?.Topics?.filter((childTopic: any) => childTopic.ParentTopic == topic.DiscussionTopicId);
        const isActive = discussion.CurrentTopic == topic.DiscussionTopicId;
        return (
            <div className="topic-box">
                <div className="topic-popover-container" style={{ border: "", padding: "" }}>
                    <IonList id="topic" style={{ padding: '0.25em', paddingRight: 0, clear: 'both', borderTop: 'solid gray 1px', cursor: 'pointer' }}
                        className={isActive ? 'activeTopic' : ''}>

                        <IonListHeader id="topic-header" style={{ float: 'left', border: "" }} >
                            <IonButton type="button" fill="clear" onClick={() => this.toggleCollapse(topic)}>
                                {childTopics.length >= 1 ? (topic.IsCollapsed ? <IonIcon icon={arrowForward}></IonIcon> : <IonIcon icon={arrowDown}></IonIcon>) : ""}
                            </IonButton>

                            {/***
                     * NOTE: Code bellow will be added in the future using Ionic React tags
                    */}
                            {/* <Popup trigger={<img src={this.getTopicUrl(topic)} style={{ width: '2em', verticalAlign: 'middle', padding: '0.25em' }} />}
                        position="right center">
                        <div className="listItem" style={{ backgroundColor: 'white' }}>
                            <IonButton onClick={() => this.toggleCollapse(topic)}>
                                {topic.IsCollapsed ? <IonIcon icon={addOutline}></IonIcon> : <IonIcon icon={removeOutline}></IonIcon>}
                            </IonButton>
                            <div>Popup content here !!</div>
                        </div>
                    </Popup> */}
                            {/* <div className="topic-popover-container" style={{ border: "", padding: "" }} > */}
                            <IonImg id="topic-profile-icon" src={this.getTopicUrl(topic)}
                                style={{ width: '2em', verticalAlign: 'middle', padding: '0.25em', float: "left" }}
                                onMouseEnter={(e: any) => {
                                    e.persist();
                                    this.setState({ showTopicPopover: true, topicPopoverEvent: e });
                                }}

                            />
                            {
                                !isActive &&
                                <IonPopover

                                    id="topic-popover"
                                    event={this.state.topicPopoverEvent}
                                    isOpen={this.state.showTopicPopover}
                                    onDidDismiss={() => this.setState({ showTopicPopover: false, topicPopoverEvent: undefined })}
                                    showBackdrop={false}
                                >
                                    <IonGrid className="topic-grid" style={{ border: "" }}
                                    onMouseLeave={(e: any) => {
                                        e.persist();
                                        this.setState({ showTopicPopover: false, topicPopoverEvent: e });
                                    }}
                                    >
                                        <IonRow style={{ border: "" }}>
                                            <IonCol style={{ width: "70%" }}>
                                                {
                                                    <>
                                                        <IonPopover
                                                            cssClass='my-custom-class'
                                                            event={this.state.fallacyPopOverEvent}
                                                            isOpen={this.state.showFallacyPopOver}
                                                            onDidDismiss={() => this.setState({ fallacyPopOverEvent: undefined, showFallacyPopOver: false })}
                                                        >
                                                            <IonList>
                                                                {this.context.fallacies ? this.context.fallacies.map((fallacy: any) =>
                                                                    <IonItem button key={fallacy.FallacyId} onClick={() => this.addFallacy(fallacy, "Proposed")}>
                                                                        <IonLabel>
                                                                            <span>
                                                                                <img src={fallacy.Icon?.length ? fallacy.Icon[0].url : this.state.defaultFallcyIcon} style={{ width: '2em', verticalAlign: 'middle', padding: '0.25em' }} />
                                                                            </span> {fallacy.Name}
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                ) : <IonItem >
                                                                    <IonLabel>Empty</IonLabel>
                                                                </IonItem>}
                                                            </IonList>
                                                        </IonPopover>
                                                        <IonButton size="small" color="medium" onClick={
                                                            (e: any) => {
                                                                e.persist();
                                                                this.setState({ fallacyPopOverEvent: e, showFallacyPopOver: true });
                                                            }}
                                                        >
                                                            Add Fallacy
                                                        </IonButton>

                                                    </>}

                                                {
                                                    <div style={{ padding: '0.75em' }}>
                                                        {discussion?.Participants?.map((discussionParticipant: any) => {
                                                            return <div key={discussionParticipant.DiscussionParticipantId + discussion.LastModifiedTime} >
                                                                <TopicParticipant discussion={discussion} discussionParticipant={discussionParticipant}
                                                                    topic={topic} topicChanged={(event: any) => this.state.topicChanged(event)}
                                                                    participantChanged={(participantId: any) => this.state.participantChanged(participantId)} />
                                                            </div>
                                                        })}
                                                    </div>

                                                }
                                                <div style={{ clear: 'both' }}><hr /></div>
                                            </IonCol>
                                        </IonRow>

                                    </IonGrid>
                                </IonPopover>
                            }





                            <IonItem id="topic-btn"
                                style={{ border: "" }}

                                onClick={() => {
                                    this.topicChanged(topic)
                                }}
                                className={topic?.HasDisagreement ? 'disagree' : (topic?.HasAgreement ? 'agree' : '')} >

                                <IonRadio onClick={(e: any) => {

                                }}
                                    name="currentTopic" id={topic.DiscussionTopicId} value={topic.DiscussionTopicId}
                                    onChange={this.onChange} style={{ display: 'none' }}
                                />
                                <IonLabel style={{ cursor: 'pointer' }} > {topic?.Subject}</IonLabel>
                            </IonItem>
                            {/* </div> */}

                            <span >
                                {discussion?.Agreements?.filter((agreement: any) => agreement.Topic == topic.DiscussionTopicId)
                                    .map((agreement: any) =>
                                        <IonChip onClick={() => this.removeAgreement(agreement)} className={(agreement.Status + '').toLowerCase()}
                                            color={agreement.Status == 'Agree' ? 'success' : 'danger'} key={agreement?.TopicAgreementId}>
                                            <IonAvatar>
                                                <img src={this.getAgreementUrl(agreement)} style={{ width: '2em', verticalAlign: 'middle', padding: '0.25em' }} />
                                            </IonAvatar>
                                            <IonIcon icon={closeCircle} />

                                        </IonChip>)
                                }
                            </span>
                            <span style={{ borderLeft: "0.2em solid gray" }}>
                                {this.fallacyTabs(discussion, topic)}
                            </span>
                        </IonListHeader>

                        <IonGrid style={{ display: isActive ? "block" : "none" }} className="topic-grid" >
                            <IonRow style={{ border: "1px solid white" }}>
                                <IonCol>

                                    {
                                        isActive &&
                                        <>
                                            <IonPopover

                                                cssClass='fallacy-popover'
                                                event={this.state.fallacyPopOverEvent}
                                                isOpen={this.state.showFallacyPopOver}
                                                onDidDismiss={() => this.setState({ fallacyPopOverEvent: undefined, showFallacyPopOver: false })}
                                            >
                                                <IonList>
                                                    {this.context.fallacies ? this.context.fallacies.map((fallacy: any) =>
                                                        <IonItem button key={fallacy.FallacyId} onClick={() => this.addFallacy(fallacy, "Proposed")}>
                                                            <IonLabel>
                                                                <span>
                                                                    <img src={fallacy.Icon?.length ? fallacy.Icon[0].url : this.state.defaultFallcyIcon} style={{ width: '2em', verticalAlign: 'middle', padding: '0.25em' }} />
                                                                </span> {fallacy.Name}
                                                            </IonLabel>
                                                        </IonItem>
                                                    ) : <IonItem >
                                                        <IonLabel>Empty</IonLabel>
                                                    </IonItem>}
                                                </IonList>
                                            </IonPopover>
                                            <IonButton size="small" color="medium" onClick={
                                                (e: any) => {
                                                    e.persist();
                                                    this.setState({ fallacyPopOverEvent: e, showFallacyPopOver: true });
                                                }}
                                            >
                                                Add Fallacy
                                            </IonButton>

                                        </>}

                                    {
                                        isActive &&
                                        <div style={{ padding: '0.75em' }}>
                                            {discussion?.Participants?.map((discussionParticipant: any) => {
                                                return <div key={discussionParticipant.DiscussionParticipantId + discussion.LastModifiedTime} >
                                                    <TopicParticipant discussion={discussion} discussionParticipant={discussionParticipant}
                                                        topic={topic} topicChanged={(event: any) => this.state.topicChanged(event)}
                                                        participantChanged={(participantId: any) => this.state.participantChanged(participantId)} />
                                                </div>
                                            })}
                                        </div>
                                    }
                                    <div style={{ clear: 'both' }}><hr /></div>
                                </IonCol>
                            </IonRow>

                        </IonGrid>
                    </IonList>


                </div>

                {(childTopics.length > 0) &&
                    <div>
                        {(!isActive && topic.IsCollapsed) ? <div style={{ fontSize: '0.7em' }}>{childTopics.length} collapsed...</div> :
                            <div style={{ marginLeft: "1.5em" }}>
                                {childTopics.map((childTopic: any) => {
                                    return <div key={childTopic.DiscussionTopicId}>
                                        <TopicComponent discussion={discussion} topic={childTopic} topicChanged={this.state.topicChanged}
                                            participantChanged={this.state.participantChanged} />
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }


}

