import TimeAgo from 'react-timeago'
import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { addOutline, chevronBackOutline, fastFood } from "ionicons/icons";
import OrganizationHeader from '../organizations/OrganizationHeader';
import { runInThisContext } from 'vm';

export default class UserSubGroupComponent extends EffortlessBaseComponent {
    isReady: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            subGroup: undefined
        };
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        if (this.state.isReady) {
            if (!this.state.reloadCalled || (this.state.subGroup && (this.state.subGroup.ShortName != this.props.match.params.subGroupCode))) {
                this.setState({
                    subGroup: undefined
                });
                this.reloadSubGroup();
            }
        }
    }

    async reloadSubGroup() {
        if (!this.state.reloadCalled) this.setState({ reloadCalled: true });
        console.error("Componet Reload", this.props, this.state);

        let payload = this.context.createPayload()
        payload.AirtableWhere = "ShortName='" + this.props.match.params.subGroupCode + "'";
        var reply = await this.context.orgUser.GetSubGroups(payload);
        if (this.hasNoErrors(reply) && reply.SubGroups && reply.SubGroups.length) {
            var subGroup = reply.SubGroups[0];
            payload.AirtableWhere = `SubGroup='${subGroup.Name}'`
            reply = await this.context.orgUser.GetDiscussions(payload);
            if (this.hasNoErrors(reply)) {
                subGroup.Discussions = reply.Discussions;
            }

            var reply = await this.context.orgUser.GetSubGroupOwners(payload)
            if (this.hasNoErrors(reply)) {
                subGroup.Owners = reply.SubGroupOwners;
                var curr = subGroup.Owners.filter((owner: any) => owner.EmailAddress == this.context.whoAmI?.EmailAddress);
                if (!curr?.length) {
                    if (true) { //confirm('Add yourself to ' + subGroup.Name)) {
                        payload = this.context.createPayload();
                        payload.AirtableWhere = `EmailAddress='${this.context?.whoAmI?.EmailAddress}'`;
                        reply = await this.context.moderator.GetOrganizationUsers(payload);
                        if (this.context.hasNoErrors(reply)) {
                            console.error('FOUND ORG USER: ', reply)
                            payload.SubGroupOwner = {
                                SubGroup: subGroup.SubGroupId,
                                Role : 'Host',
                                Owner: reply.OrganizationUsers[0].OrganizationUserId
                            }
                            reply = await this.context.moderator.AddSubGroupOwner(payload);
                            if (this.context.hasNoErrors(reply)) {
                                console.error('ADDED subgroup owner', reply);
                                subGroup.Owners.push(reply.SubGroupOwner);                                
                            }
                        }
                    }
                }
                console.error('CHECKING SUBGROUP OWNERS: ', subGroup, curr, this.context.whoAmI)
            }

            console.error("Ep: ", subGroup)

            var newState = { subGroup: subGroup, reloadRequested: true }
            console.error('NEW STATE: ', newState);
            this.setState(newState);
        }
    }

    render() {
        console.error('rendering');
        const { subGroup } = this.state;
        return (
            <IonPage>
                {!subGroup ? <IonLoading isOpen={true} message={'Loading...'} duration={5000} /> :
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonMenuButton />
                                </IonButtons>
                                <IonTitle>
                                    <div style={{ float: 'right' }}>
                                        <button onClick={() => this.reloadSubGroup()}>Reload</button>
                                    </div>
                                    <OrganizationHeader organizationLogo={subGroup?.OrganizationLogo}
                                        name={subGroup?.OrganizationGroupDisplayName || "..."}
                                        subName={subGroup?.DisplayName || 'Loading...'} />
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent fullscreen>
                            <div style={{ padding: '1em' }}>
                                <IonButton routerLink={"/group/" + subGroup?.OrganizationGroupShortName} style={{ float: 'right' }}><IonIcon icon={chevronBackOutline}></IonIcon> {subGroup?.OrganizationGroupDisplayName}</IonButton>
                                <div>
                                    <h2>{subGroup?.PluralSubGroupOwnerDisplayName || 'Owner'} ({subGroup?.Owners?.length || 'none'})</h2>
                                    <div style={{ clear: 'both' }}>
                                        {subGroup?.Owners?.map((owner: any) => {
                                            return <div className="hostContainer" key={owner.SubGroupOwnerId + ':' + owner.LastModified}>
                                                <div className="hostItem">
                                                    {owner.OwnerAvatar && owner.OwnerAvatar.length &&
                                                        <img src={owner.OwnerAvatar[0].url} style={{ width: '3em', padding: '0.25em', verticalAlign: 'middle' }} />}
                                                    {owner.OwnerDisplayName} - {owner.Role}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div style={{ clear: 'both', padding: '1.5em', borderTop: 'solid 1px gray' }}>
                                    <div style={{ float: 'right' }}>
                                        <IonButton routerLink={"/sub/" + subGroup?.ShortName + "/add"}><IonIcon icon={addOutline}></IonIcon> Add {subGroup?.DiscussionDisplayName || 'Discussion'} Now</IonButton>
                                    </div>
                                    <h3>{subGroup?.DisplayName} {subGroup?.PluralDiscussionDisplayName || 'Discussions'}</h3>
                                    <ol>
                                        {subGroup?.Discussions?.sort((a: any, b: any) => a.AutoNumber > b.AutoNumber ? -1 : 1)
                                            ?.map((discussion: any) => {
                                                return <div key={discussion.SubGroupCallId}>
                                                    <div className="listItem">
                                                        <div style={{ float: 'right', width: '30%' }}>
                                                            {discussion?.DiscussionParticipantDisplayNames
                                                                ?.sort((a: any, b: any) => a > b ? -1 : 1)
                                                                ?.map((participant: any) => <div>
                                                                    - {participant}
                                                                </div>)}
                                                        </div>


                                                        <IonButton size="small" routerLink={"/discussion/" + discussion.ShortName}> {discussion?.Subject}</IonButton>
                                                        <div style={{ fontSize: '0.8em' }}>
                                                            <TimeAgo date={discussion?.CreatedTime + 'Z'} />
                                                        </div>
                                                        <div style={{ clear: 'both' }}></div>
                                                    </div>
                                                </div>
                                            })}
                                    </ol>
                                </div>
                            </div>
                        </IonContent>
                    </>}

            </IonPage>

        );
    }
}