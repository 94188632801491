import React from 'react';
import { GlobalDataService } from '../GlobalDataService';


export class EffortlessBaseComponent extends React.Component<any, any> {
    isAuthenticated: boolean = true;

    constructor(props: any) {
        super(props)
        this.state = {
            isReady : false,
            isModeratorReady : false
        }
    }
    
    static contextType = GlobalDataService;
    context!: React.ContextType<typeof GlobalDataService>;

    componentDidUpdate(){
        if (this.state.isReady && this.isAuthenticated && !this.context.accessToken){
            console.error("REDIRECTING TO LOGIN!!!!", this.isAuthenticated, this.context?.accessToken, this.props.location.pathname);
            if (this.props.location.pathname != '/login') this.props.history.push("/login");
        }
    }

    componentDidMount() {
        var self = this;
        self.context.readiness$.subscribe((ready:any) => {
            if (ready) {
                console.error('MARKING ISREADY TRUE');
                self.onReady();
                this.setState({isReady:true, isModeratorReady: (this.context.moderator && this.context.moderator.client.connected)});
            }
        });
        self.context.notReadiness$.subscribe((notReady:any) => {
            if (notReady) {
                console.error('MARKING ISREADY FALSE');
                self.onNotReady();
                this.setState({isReady:false});
            }
        });
    }

    async onReady() {

    }

    async onNotReady() {

    }

    hasNoErrors(payload : any) {
        var hasError = payload && payload.ErrorMessage;
        if (hasError) console.error('ERROR: ', hasError);
        return !hasError; 
    }
}