import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';
import { EffortlessBaseComponent } from '../../../services/EffortlessBaseComponent';
import AddOrgBase from './AddOrgBase';

export default class AddRandomThoughtsComponent extends AddOrgBase {    
    render() {
        return <div>
            <h3>Add Random Thoughts</h3>
            <IonItem>
                <IonLabel>Your Name: </IonLabel>
                <IonInput value={this.state.newOrg?.Name} 
                            onIonChange={(event) => this.updateNewOrg(event, 'Name')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Category </IonLabel>
                <IonInput value={this.state.newOrg?.OrganizationGroup} 
                            onIonChange={(event) => this.updateNewOrg(event, 'FirsOrgroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Subject </IonLabel>
                <IonInput value={this.state.newOrg?.SubGroup} 
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstSubGroup')} >
                </IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Brainstorm: </IonLabel>
                <IonInput value={this.state.newOrg?.Discussion} 
                            onIonChange={(event) => this.updateNewOrg(event, 'FirstDiscussion')} >
                </IonInput>
            </IonItem>
            <IonItem lines="none">
                <IonButton onClick={(e: any) => this.addOrganization(e)}>Create Random Thoughts</IonButton>
            </IonItem>
        </div>
    }
}