
import React from "react";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { addCircle, lockOpen } from 'ionicons/icons';
import { useHistory } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent';
import { OrganizationCard } from "./OrganizationCard";
import { AnyNaptrRecord } from "dns";
// import "../../App.css";

export default class OrganizationsComponent extends EffortlessBaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            organizations: [],
            userOrganizations : []
        };
    }

    async reloadOrganizations() {
        var reply = await this.context.moderator.GetOrganizations(this.context.createPayload());
        if (this.hasNoErrors(reply)) {
            var newState = { organizations: reply.Organizations, reloadCalled: true, userOrganizations: [] }
            reply = await this.context.orgUser.GetOrganizations(this.context.createPayload());
            if (this.hasNoErrors(reply)) {
                newState.userOrganizations = reply.Organizations;
                this.setState(newState);
            }
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        if (this.state.isModeratorReady && !this.state.reloadCalled) {
            this.reloadOrganizations()
        }
    }

    goToUrl(e: any, organization: any) {
        // e.preventDefault();
        this.props.history.push("/org/" + organization.ShortName);
    }

    addOrganization(e: any) {
        e.preventDefault();
        this.props.history.push('/organizations/add');
    }


    render() {
        const { organizations } = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>
                            {this.state.reloadCalled ? <div>
                                {organizations ? <span>All {organizations?.length} Organizations</span> : <span>Loading organizations...</span>}
                            </div> :
                                <div>Loading organizations {window.GDS?.whoAmI && <span> for {window.GDS?.whoAmI?.EmailAddress}</span>}</div>
                            }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    {this.state.reloadCalled ?
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={() => this.reloadOrganizations()}>
                                        Reload
                                    </IonButton>

                                </IonCol>
                                <IonCol>
                                    <IonCard onClick={(e) => this.addOrganization(e)}>
                                        <IonItem>
                                            <IonIcon size="large" icon={addCircle} />
                                            <IonLabel>
                                                Add Organization
                                            </IonLabel>
                                        </IonItem>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow class="ion-justify-content-center" >
                                {organizations?.sort((a: any, b: any) => a.AutoNumber > b.AutoNumber ? 1 : -1)
                                    .map((organization: any) =>
                                        <IonCol style={{ border: "", width: "15em" }} key={organization.OrganizationId} >
                                            <div style={{ border: "" }} onClick={(e: any) => this.goToUrl(e, organization)} >
                                                <OrganizationCard
                                                    Id={organization.OrganizationId}
                                                    Attachments={organization.Attachments}
                                                    Name={organization.Name}
                                                    DisplayName={organization.OrganizationDisplayName}
                                                    Notes={organization.Notes}
                                                />
                                            </div>
                                        </IonCol>
                                    )}
                            </IonRow>

                            <IonRow>
                                <IonCard onClick={(e) => this.addOrganization(e)}>
                                    <IonItem>
                                        <IonIcon size="large" icon={addCircle} />
                                        <IonLabel>
                                            Add Organization
                                        </IonLabel>
                                    </IonItem>
                                </IonCard>
                            </IonRow>

                            <IonRow style={{paddingTop:'1em'}}>
                                <IonIcon size="large" icon={lockOpen} />
                                <IonTitle>
                                    3rd party Organizations
                                </IonTitle>
                            </IonRow>

                            <IonRow>

                                {this.state.userOrganizations.map((userOrg :any) => <IonItem>
                                    <IonButton size="small" routerLink={"/userOrg/" + userOrg.ShortName}> {userOrg?.Name}</IonButton>
                                </IonItem>)}
                            </IonRow>
                        </IonGrid> : <div>...</div>}
                    {/* <div>
                    <div>
                            {organizations?.sort((a: any, b: any) => a.AutoNumber > b.AutoNumber ? 1 : -1)
                                .map((organization: any) =>
                                    <div key={organization.OrganizationId} className="organizationBlock" onClick={(e) => this.goToUrl(e, organization)}>
                                        {organization.Attachments?.length && <img style={{ float: 'left', width: '8em' }} src={organization.Attachments[0].url} />}
                                        <h3>{organization.Name}</h3>
                                        <div>
                                            <b>{organization.OrganizationDisplayName}</b>
                                        </div>

                                        <div>
                                            {organization.Notes}
                                        </div>
                                    </div>
                                )}
                            <div className="organizationBlock" onClick={(e) => this.addOrganization(e)}> Add organization</div>
                        </div>
                    </div> */}

                </IonContent>
            </IonPage>
        );
    }
}