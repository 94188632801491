
import React from "react";
import TimeAgo from 'react-timeago'
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useHistory, useParams } from "react-router";
import { GlobalDataService } from "../../GlobalDataService";
import { GDS } from "../../services/gds.service";
import { EffortlessBaseComponent } from '../../services/EffortlessBaseComponent'
import { addOutline, pencilOutline } from "ionicons/icons";

export default class UserOrganizationComponent extends EffortlessBaseComponent {
    didMount: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            organization: undefined,
            reloadCalled: false,
        };
    }

    async reloadOrganization() {
        if(this.state.organization != undefined) this.setState({organization: undefined });

        if (!this.state.reloadCalled) this.setState({ reloadCalled: true });
        let payload = this.context.createPayload()
        payload.AirtableWhere = "ShortName='" + this.props.match.params.orgCode + "'";
        var reply = await this.context.orgUser.GetOrganizations(payload);
        if (this.hasNoErrors(reply) && reply.Organizations && reply.Organizations.length) {
            var organization = reply.Organizations[0];
            console.error('GOT ORG: ', organization);
            payload.AirtableWhere = `OrganizationName='${organization.Name.replace(/\'/g, "\\'")}'`;
            reply = await this.context.orgUser.GetSubGroups(payload);
            if (this.hasNoErrors(reply)) {
                organization.SubGroups = reply.SubGroups;
            }
            var newState = { organization: organization, reloadCalled: true };
            this.setState(newState);
        } else {
            console.error('ERRO LOADING ORG: ', reply.ErrorMessage);
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        console.error('Checking Organization: ', this.state)
        if (this.state.isReady &&
            (!this.state.reloadCalled || (this.state.organization && (this.state.organization.ShortName != this.props.match.params.orgCode)))) {
            this.reloadOrganization();

        }
    }


    render() {
        console.error('rendering');
        const { organization } = this.state;
        return (
            <IonPage>
                {!organization ? 
                <IonLoading isOpen={true}  message={'Loading...' }  />
                :
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonMenuButton />
                                </IonButtons>
                                <div style={{ float: 'right' }}>
                                    <button onClick={() => this.reloadOrganization()}>Reload</button>
                                </div>
                                <IonTitle>{organization?.Name || 'Loading organization...'}
                                    <IonButton size="small" color="light" routerLink={"/org/" + organization?.ShortName + '/edit'}><IonIcon icon={pencilOutline}></IonIcon></IonButton>
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent fullscreen>
                            <div>
                                <div>
                                    {organization?.Attachments?.length ? <div><img src={organization?.Attachments[0].url} style={{ width: '6em', float: 'left' }} /></div> : undefined}
                                    <h3>{organization?.Groups?.length} {organization?.PluralGroupDisplayName} </h3>
                                    <div>
                                        <b>Description: </b>
                                    </div>
                                    <div>
                                        {organization?.Notes || 'no description yet...'}
                                    </div>
                                    <div style={{ clear: 'both' }}>
                                        {organization?.Discussions?.map(((discussion:any) => <IonItem key={discussion.Name}>
                                                    <div className="listItem">
                                                        <div style={{ float: 'right', width: '30%' }}>
                                                            {discussion?.DiscussionParticipantDisplayNames
                                                                ?.sort((a: any, b: any) => a > b ? -1 : 1)
                                                                ?.map((participant: any) => <div>
                                                                    - {participant}
                                                                </div>)}
                                                        </div>


                                                        <IonButton size="small" routerLink={"/discussion/" + discussion.ShortName}> {discussion?.Subject}</IonButton>
                                                        <div style={{ fontSize: '0.8em' }}>
                                                            <TimeAgo date={discussion?.CreatedTime + 'Z'} />
                                                        </div>
                                                        <div style={{ clear: 'both' }}></div>
                                                    </div>
                                        </IonItem>))}
                                        {organization?.SubGroups
                                            ?.sort((a: any, b: any) => a.GroupNumber > b.GroupNumber ? -1 : 1)
                                            .map((subGroup: any) => {
                                                return <div key={subGroup.Name} className="listItem">
                                                    <div style={{ float: 'right' }}>
                                                        {subGroup.SubGroups?.length || "0"} {subGroup.SubGroups?.length == 1 ? organization?.SubGroupDisplayName : organization?.PluralSubGroupDisplayName}
                                                    </div>
                                                    <IonButton routerLink={"/userSub/" + subGroup.ShortName} style={{ float: 'left' }}> {subGroup?.DisplayName}</IonButton>
                                                </div>
                                            })}
                                    </div>
                                </div>
                            </div>
                        </IonContent>
                    </>}
            </IonPage>
        );
    }
}