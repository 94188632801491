import { IonPage } from "@ionic/react";
import React, { useContext } from "react";
import { EffortlessBaseComponent } from "../../services/EffortlessBaseComponent";

export default class LogoutComponent extends EffortlessBaseComponent {

    constructor(props:any) {
        super(props);
    }

    componentDidUpdate() {        
        window.GDS.saveAccessToken(null);
    }

    render() {
        window.location.href = '/login'
        return <IonPage>
            <div>
                Logging out now
            </div>
        </IonPage>
    }
}