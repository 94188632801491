import { IonCol, IonCard, IonImg, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonThumbnail, IonText, IonAvatar } from "@ionic/react";
import { Hidden } from "@material-ui/core";

export function OrganizationCard(props: any) {
    return (
        <IonCard key={props.Id || null} >
            {props.Attachments?.length &&
            <IonAvatar style={{margin: "0.5em"}}>
                    <IonImg src={props.Attachments[0].url || "?"} />
            </IonAvatar>
            }
            <IonCardHeader>
                <IonCardTitle style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"} } >{props.Name || "Name"}</IonCardTitle>
                <IonCardSubtitle style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"} } >{props.DisplayName || "Sub name"}</IonCardSubtitle>
                <IonCardContent style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"} }>
                    <IonText >
                        {props.Notes || "Notes ..."}
                    </IonText>
                </IonCardContent>
            </IonCardHeader>

        </IonCard>
    )

}